.tick {
  line {
    opacity: 0 !important;
  }
}

.positive {
  color: #FC6404;
  fill: #FC6404;
  font-weight: $font-weight-bold;
}

.negative {
  color: $brand-primary;
  fill: $brand-primary;
  font-weight: $font-weight-bold;
}

.wizicon-investors-arrow-stock.positive {
  transform: rotateX(180deg);
}

.layout-investors {
  .section-jumbotron-background {
    height: 300px;
    position: relative;
    overflow: hidden;

    img {
      bottom: -5%;
      filter: url(#ff-blur);
      max-width: none;
      position: absolute;
      right: -5%;
      width: 110%;
    }
  }

  .section--jumbotron-figures {
    margin-bottom: 100px;

    .section-title {
      color: $brand-dark;
      font-family: $font-family-primary;
      font-size: $font-size-xxxl;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }

  .card--asset--investors {
    padding: 10px 55px;
    border-bottom: 1px solid #7E8083;
    background-color: $brand-light;

    .card-element--icon {
      float: left;
      padding: 10px 20px 10px 0;

      .wizicon {
        @include size(25px, 25px);
      }
    }

    .card-title {
      color: $brand-dark;
      font-family: $font-family-primary;
    }

    .card-subtitle {
      color: #7E8083;
      margin-bottom: 0;
      margin-top: 0;
    }

    .card-title-widget {
      align-items: center;
      color: #7E8083;
      display: flex;
      flex-direction: column-reverse;
      float: right;
      font-size: $font-size-xxs;
      margin-right: 15px;
    }

    .wizicon-asset-link-to {
      color: $brand-primary;
      fill: $brand-primary;
    }
  }

  .section--text,
  .section--chart-any .card-title {
    color: #7B7E83;
    font-family: $font-family-primary;
    font-size: $font-size-lg;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    &.layout-figures {
      .section--composite .row {
        display: flex;
        align-items: center;
      }
    }
  }
}

.layout--show.layout-investors.layout-stock,
.layout--show.layout-investors.layout-regulatedinformation {
  .section--stock {
    margin-top: -200px;
  }
}

.layout--show.layout-investors.layout-regulatedinformation {
  .panel-group--assets {
    clear: right;
    padding-top: 30px;

    .panel-heading {
      background-color: inherit;

      a:hover,
      a:active,
      a:visited,
      a:focus {
        color: inherit;
      }
    }

    .panel-widget {
      float: right;
      margin-right: 30px;
      padding: 11px 0;
      transform: rotate(180deg);
    }

    .panel-title {
      font-family: $font-family-primary;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      padding-left: 55px;

      .contents-length {
        background-color: $brand-primary;
        border-radius: 50%;
        color: $brand-secondary;
        padding: 0px 6px;
        position: absolute;
        right: 50px;
      }
    }

    .panel--asset:after {
      background-color: #7E8083;
      content: '';
      display: block;
      height: 1px;
      margin: 0 auto;
      margin-left: 55px;
      text-align: center;
      width: calc(100% - 80px);
    }

    .panel--asset.in {
      background-color: $brand-light;

      .panel-widget {
        transform: rotate(0deg);
        transition: all 0.3s ease-in;
      }

      &:after {
        content: none;
      }
    }
  }

  .section--any {
    .dropdown {
      float: right;

      .dropdown-toggle {
        background-color: #9EA0A4;
        font-weight: $font-weight-bold;
        margin-bottom: 0;

        .wizicon-angle-right {
          @include size(15px, 15px);
          color: $brand-secondary;
          fill: $brand-secondary;
          margin-left: 10px;
          transform: rotate(90deg);
          vertical-align: middle;
        }
      }

      .dropdown-menu {
        width: 100%;
      }
    }
  }
}

.js-column-legend-wrapper {
  margin-left: 60px;
}

.js-column-legend-subwrapper {
  display: inline-flex;
  justify-content: center;
  text-align: center;
}

.js-column-legend-subwrapper-container {
  font-size: $font-size-sm;

  .js-column-legend {
    font-size: 80%;
  }
}

.js-column-legend {
  display: inline-block;
  font-family: $font-family-secondary;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  text-align: center;
}

.cssload-loader {
  border: 4px solid #6095C1;
  animation: cssload-loader 2.3s infinite ease;
  -o-animation: cssload-loader 2.3s infinite ease;
  -ms-animation: cssload-loader 2.3s infinite ease;
  -webkit-animation: cssload-loader 2.3s infinite ease;
  -moz-animation: cssload-loader 2.3s infinite ease;
  border-radius: 50%;
  display: block;
  height: 29px;
  margin:0 auto;
  position: relative;
  width: 29px;
}

.cssload-loader-inner {
  animation: cssload-loader-inner 2.3s infinite ease-in;
  -o-animation: cssload-loader-inner 2.3s infinite ease-in;
  -ms-animation: cssload-loader-inner 2.3s infinite ease-in;
  -webkit-animation: cssload-loader-inner 2.3s infinite ease-in;
  -moz-animation: cssload-loader-inner 2.3s infinite ease-in;
  background-color: #53606B;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

@keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@-o-keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@-ms-keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@-webkit-keyframes cssload-loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }
}

.section--sidebar {
  margin-top: -50px;
  padding: 12px 20px 12px 21px;

  .section-title {
    border-bottom: 1px solid $border-color;
    color: $brand-primary;
    font-size: $font-size-xxxl;
    line-height: 32px;
    margin: 0 20px 24.5px 0;
    padding-bottom: 9px;

    &::before {
      background-color: $brand-primary;
      bottom: -2.5px;
      content: "";
      height: 5px;
      left: 0;
      position: absolute;
      width: 105px;
    }

    .section-title-icon {
      display: none;
      float: right;
      transform: rotate(-180deg);
      transition: transform .3s ease-out;

      .wizicon {
        fill: $brand-dark;
      }
    }

    & > .collapsed {
      .section-title-icon {
        transform: rotate(0deg);
        transition: transform .3s ease-out;
      }
    }

    @media screen and (max-width: $screen-md) {
      border-bottom: none;

      &::before {
        content: none;
      }
    }
  }

  .section--features {
    .card--feature {
      @include make-card-layout--list(40px, false)
    }
  }

  .section--spokespersons {
    .section-block {
      margin-bottom: 6px;

      .gallery.gallery--deck {
        margin: 0 -9px;

        .gallery-item {
          margin: 0 0 10px;
          padding: 0 9px;
          width: unset;
        }

        .gallery-clearfix {
          display: none;
        }
      }
    }

    .card--spokesperson {
      &.card--alt {
        background: transparent;
        border: none;

        .card-img {
          all: unset;
          border-radius: 100%;
          height: 55px;
          margin: 0 auto;
          overflow: hidden;
          padding: 0;
          width: 55px;

          img {
            height: 100%;
            position: relative;
            width: 100%;
          }
        }

        .js-buckle-container.offie--active {
          margin-top: 50px;
        }
      }
    }

    .section-footer {
      display: none;
    }

    .section-btn {
      @include make-button--primary-1();
      padding: 10px 0;

      .section-btn-text {
        font-weight: $font-weight-regular;
      }
    }
  }

  .section--newsletter {
    margin-bottom: 30px;

    .section-title {
      border-bottom: $section-title-border;
    }

    .section-block {
      .form--name,
      .form--email {
        width: 100%;
      }

      .form-submit {
        width: 50%;
      }
    }

    .form--newsletter {
      background-color: $brand-light;
      border: 1px solid $border-color;
      margin-bottom: 0;
      padding: 25px;
    }
  }

  .section--tags {
    &.section--highlight {
      background-color: transparent;
      padding: 0;

      .section-title {
        color: $brand-primary;
        font-size: $font-size-xxxl;
        line-height: 32px;
        padding-bottom: 9px;
      }

      .section-block {
        padding: 0;
      }

      .list--tags {
        justify-content: flex-start;
        margin: 0px -5px;
        width: 100%;

        .list-item {
          display: inline-flex;
          margin: 0 5px 10px;

          .label--tags {
            background-color: $brand-primary;
            color: $brand-secondary;
            white-space: break-spaces;
          }
        }
      }
    }
  }

  .section--webidentities {
    .list--webidentities {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: $screen-md) {
    display: none;
    margin-top: 32px;
    padding: 0 96px;
  }
}

.navbar {
  border-radius: 0;
  border-width: 0;
}

.navbar-content,
.navbar-element {
  @include clearfix();
}

.navbar-element--main {
  margin-right: 50px;
}

@media screen and (max-width: $grid-float-breakpoint-max) {
  .navbar-nav {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.navbar--app {
  background-color: $brand-light;
  border-width: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
}

.navbar-brand {
  border-right: 1px solid $border-color;
  display: block;
  float: none;
  padding: 0;
  position: relative;

  .navbar-brand-img {
    display: block; // Overrides common/style.css
    height: auto; // Overrides common/style.css
    margin: auto;
    max-width: 100%;
  }

  svg.navbar-brand-img {
    display: block;
    fill: #7E8083;
    height: 50px;
    margin: auto;
    padding: 10px 15px;
    width: 170px;
  }
}

.navbar--channel {
  @include navbar-size(46px, 10px, 0);

  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;

  .nav--channel-1 {
    @include make-nav-variant(
      $text-color, // $link-color,
      $text-color, // $link-color-hover,
      transparent, // $link-bg,
      transparent, // $link-bg-hover,
      $brand-primary, // $link-color-active,
      transparent, // $link-bg-active,
      $text-muted, // $link-color-disabled,
      transparent, // $link-bg-disabled,
    );

    .nav-link {
      font-family: $font-family-primary;
      font-size: $font-size-md;
      font-weight: unset;
      line-height: 17px;
      padding: 0;
      text-transform: uppercase;

      .nav-link-text {
        vertical-align: middle;
      }

      .wizicon {
        @include size(13px, 13px);
        vertical-align: middle; // TODO: there's probably a better thing to do.
      }

      &:hover {
        color: $brand-primary;
      }
    }

    .offie--active.nav-link .wizicon,
    .active > .nav-link .wizicon {
      fill: $brand-primary;
    }
  }

  .nav--channel-2 {
    .nav-link {
      border: none;
      padding-bottom: 10px;
      padding-top: 10px;
      text-transform: none;

      &:hover {
        color: $brand-secondary;
      }
    }
  }
}

.navbar--channel {
  border: none;

  > .container {
    align-items: flex-end;
    display: flex;
    padding: 43px 40px 42px 0;
    width: 100%;

    &::before {
      background: rgb(116,33,39);
      background: linear-gradient(180deg, rgba(116,33,39,1) 0%, rgba(116,33,33,1) 13%, rgba(232,87,61,1) 13%, rgba(232,87,61,1) 30%, rgba(193,32,23,1) 30%, rgba(193,32,23,1) 50%, rgba(116,33,39,1) 50%, rgba(116,33,39,1) 63%, rgba(232,87,61,1) 63%, rgba(232,87,61,1) 80%, rgba(193,32,23,1) 80%, rgba(193,32,23,1) 100%);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 11px;
    }

    .section-img {
      display: inline-block;
      flex-basis: 117px;
      margin: 0px 32px 0 30px;
      min-width: 117px;

      img {
        height: 95px;
        object-fit: cover;
        width: 100%;
      }
    }

    .navbar-element--main {
      align-items: flex-end;
      display: flex;
      margin: 0;

      &.navbar-element-mobile {
        padding-top: 20px;

        .navbar-nav {
          > .nav-item {
            .nav-link {
              border: none;
              padding: 14px 14px 14px 0;

              .nav-link-icon {
                display: inline-block;
                margin-left: 5px;

                > .wizicon {
                  color: $brand-secondary;
                  fill: $brand-secondary;
                }
              }
            }
          }
        }
      }

      .navbar-nav,
      .navbar-nav-mobile {
        margin: 0 -2px;
        float: none;

        .offie-dropdown {
          .offie-block {
            background-color: transparent;
            box-shadow: none;
          }
        }

        > .nav-item {
          &.nav-item--extra {
            display: none;
          }

          > .nav-link {
            border-bottom: 6px solid;
          }

          .nav-link {
            margin: 0 1px;
            padding: 18px 14px 17px;

            &.offie--active {
              color: $brand-dark;
              font-family: $font-family-bold;
            }

            &:hover {
              color: $brand-dark;
            }

            .nav-link-icon {
              display: none;
            }
          }
        }

        .nav-item {
          .offie-dropdown {
            .offie-block {
              .nav {
                > .nav-item {
                  &:last-child {
                    .nav-link {
                      border-bottom: none;
                    }
                  }

                  .nav-link {
                    margin: 0;
                    padding: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .nav-item--search {
      flex-basis: 424px;
      margin-left: auto;

      .form--clientsearch {
        .input-group {
          align-items: center;
          border: 1px solid $border-color-dark;
          display: flex;

          input.form-control {
            border: none;
            color: $brand-dark;
            height: auto;
            padding: 17px 15px;
            width: calc(100% - 60px);

            &::-webkit-input-placeholder {
              line-height: 16px;
            }
          }

          .input-group-btn {
            display: inline-block;
            position: absolute;
            right: 14px;
            width: auto;

            .btn-default {
              background-color: transparent;
              border: none;
              padding: 0;

              &:hover {
                background-color: transparent;
              }

              .wizicon {
                height: 30px;
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
}

b {
  font-family: $font-family-medium;
  font-weight: normal;
}

strong {
  font-family: $font-family-bold;
  font-weight: normal;
}

small {
  font-size: $font-size-xs;
}

h4 {
  font-size: $font-size-lg;
}

h3 {
  font-size: $font-size-xlg;
}

h2 {
  font-size: $font-size-xxl;
}

h1 {
  font-size: $font-size-xxxl;
}

.d-none {
  display: none;
}

.uppercase-all {
  text-transform: uppercase;
}

.uppercase-first:first-letter {
  text-transform: uppercase;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin section-title-bottom-border($border-color) {
  &:after {
    background: $border-color;
    content: "";
    display: inline-block;
    height: 4px;
    width: 64px;
  }
}

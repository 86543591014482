.scrolloff { // Added via javascript
  pointer-events: none;
}

.copyright {
  color: $brand-dark-grey;
  font-family: $font-family-bold;
  font-size: $font-size-sm;
  line-height: 13px;
  margin: 13px 38.5px 13px 0;
  text-align: right;

  @media screen and (max-width: $screen-md) {
    margin-right: 0;
  }
}

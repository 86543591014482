.panel-widget {
  float: left;

  &--collapse-index {
    display: none;
  }
}

.panel-widget--collapse-indicator {
  float: right;

  .wizicon {
    color: $brand-dark;
    fill: $brand-dark;
    transition: transform 0.3s;
  }
}

.panel--web_publication_collapse.in .panel-widget--collapse-indicator .wizicon {
  transform: rotate(0deg);
}

.panel-widget--collapse-indicator .wizicon {
  transform: rotate(-180deg);
}

.panel-group {
  .panel--web_publication_collapse.panel--highlight {
    .panel-title {
      color: $text-color;
    }
  }
}

.panel--web_publication_collapse {
  &.in {
    .panel-heading {
      > a[data-toggle="collapse"] {
        &::before {
          content: "";
          height: 1px;
          width: calc(100% - 104px);
          background: $border-color;
          position: absolute;
          bottom: 0;
          left: 50%;
          -webkit-transform: translate(-50%, 0)
        }
      }
    }
  }

  .panel-heading {
    background-color: $brand-light-gray;

    > a[data-toggle="collapse"] {
      position: relative;
    }
  }

  .panel-title {
    @include make-font-title--1();
    font-size: $font-size-xxl;
    line-height: 28px;
    padding: 24px 52px;
  }

  .panel-widget--collapse-index {
    display: none;

    .panel-widget-item {
      @include size(59px, 59px);
      @include make-font-title--4();

      line-height: 1;
      padding: 20px;
      text-align: right;
    }
  }

  .panel-widget-item {
    padding: 24px 52px 24px 0;
  }

  &.in {
    &:after {
      left: 0px;
    }

    .panel-heading,
    .panel-collapse {
      background-color: $brand-light-gray;
    }
  }

  &.panel--highlight {
    .panel-title,
    .panel-widget--collapse-index .panel-widget-item {
      color: $text-color;
    }
  }

  .collapse-body {
    padding: 10px 52px 50px;

    &.content-text {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .panel-title {
      padding: 16px;
    }

    .panel-widget-item {
      padding: 22px 16px 22px 0;
    }

    .collapse-body {
      padding: 10px 16px 50px;
    }
  }
}

.panel-group {
  .panel--expert_expertise {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      left: 0;
    }

    .panel-heading {
      background-color: $brand-secondary;

      .panel-title.panel-title--dates {
        color: $brand-dark;
        font-family: $font-family-primary;
        font-size: $font-size-xl;
        font-weight: $font-weight-regular;
        line-height: 24px;
        padding: 0;

        &::before {
          bottom: 1px;
          content: ".";
          padding-right: 3px;
          position: relative;
        }
      }

      .panel-widget--collapse-indicator,
      .panel-widget--dates {
        display: none;
      }
    }

    .panel-body {
      @media screen and (max-width: $grid-float-breakpoint) {
        padding-left: 20px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &.in {
      .panel-heading .wizicon-collapse-indicator {
        transform: rotate(-180deg);
        transition: transform ease-in 0.3s;
      }
    }

    &:last-child:after {
      content: none;
    }
  }
}

.panel-group--program {
  .panel--program:last-child .panel-title {
    margin-bottom: 0;
  }
}

.panel--program {
  .panel-program--body {
    margin-bottom: 10px;
  }

  .panel-heading {
    background-color: $brand-secondary;
  }

  .panel-title {
    @include make-font-11();
    background-color: $brand-light;
    color: $brand-dark;

    .panel-title-icon {
      float: right;

      .wizicon {
        fill: $brand-dark;
      }
    }
  }

  .panel-body {
    background-color: $brand-secondary;
    padding: 0;
  }

  .panel--subprogram {
      border-bottom: 1px solid $border-color;

    .panel-heading {
      background-color: $brand-secondary;

      .panel-title {
        background-color: inherit;
        color: inherit;
        padding: 20px;
        margin-bottom: 0;
      }

      .panel-widget--dates {
        color: $brand-primary;
        float: left;
        width: 18%;

        .panel-widget-item {
          padding: 20px;
          display: flex;
          align-items: center;
        }

        .wizicon {
          @include size(13px, 13px);
          color: $brand-primary;
          fill: $brand-primary;
          line-height: 1;
        }
      }

      .panel-widget--performers {
        float: right;

        .mediaobj--performer img {
          width: 30px;
        }

        .panel-widget-item {
          padding: 10px 5px;
        }
      }

      .panel-widget--collapse-indicator {
        float: right;
        transition: transform 0.3s ease-out;
      }
    }

    .panel-collapse {
      .panel-body {
        margin-left: 18%;
        padding: 20px;

        .panel-text {
          font-size: $font-size-base;
        }
      }
    }
  }

  &.in {
    .panel-title {
      background-color: $brand-dark;
      color: $brand-secondary;

      .panel-title-icon {
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in;

        .wizicon {
          fill: $brand-light;
        }
      }
    }

    .panel--subprogram.in {
      .panel-widget--collapse-indicator {
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in;
      }
    }
  }
}

.panel--content-download {
  margin: 0 auto;
  max-width: 205px;

  .panel-heading {
    align-items: center;
    background: $brand-primary;
    display: inline-flex;
    justify-content: center;
    width: 100%;

    .panel-title {
      @include make-font-9();

      align-items: center;
      color: $brand-secondary;
      display: flex;
      flex-direction: row-reverse;
      line-height: 16px;
      text-align: center;

      .panel-title-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .wizicon-icon-download {
      margin-right: 14px
    }
  }
}

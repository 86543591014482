.carousel {
  position: relative;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;

  &.gallery-list {
    .gallery-item {
      margin-bottom: 43px;
    }
  }

  > .item {
    -o-transition: 0.6s ease-in-out left;
    -webkit-transition: 0.6s ease-in-out left;
    display: none;
    position: relative;
    transition: 0.6s ease-in-out left;

    > img,
    > a > img {
      line-height: 1;
    }
  }

  > .active,
  > .next,
  > .prev {
    display: block;
  }

  > .active {
    left: 0;
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }

  > .prev {
    left: -100%;
  }

  > .next.left,
  > .prev.right {
    left: 0;
  }

  > .active.left {
    left: -100%;
  }

  > .active.right {
    left: 100%;
  }

  @media all and (transform-3d), (-webkit-transform-3d) {
    > .item {
      -moz-backface-visibility: hidden;
      -moz-perspective: 1000px;
      -moz-transition: -moz-transform 0.6s ease-in-out;
      -o-transition: -o-transform 0.6s ease-in-out;
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000px;
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      backface-visibility: hidden;
      perspective: 1000px;
      transition: transform 0.6s ease-in-out;
    }

    > .item.next,
    > .item.active.right {
      -webkit-transform: translate3d(100%, 0, 0);
      left: 0;
      transform: translate3d(100%, 0, 0);
    }

    > .item.prev,
    > .item.active.left {
      -webkit-transform: translate3d(-100%, 0, 0);
      left: 0;
      transform: translate3d(-100%, 0, 0);
    }

    > .item.next.left,
    > .item.prev.right,
    > .item.active {
      -webkit-transform: translate3d(0, 0, 0);
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }
}

.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 50%;
  list-style: none;
  margin-bottom: 0;
  margin-left: -30%;
  padding-left: 0;
  position: absolute;
  text-align: center;
  width: 60%;
  z-index: 2;

  li {
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid $brand-primary;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin-left: 7px;
    margin-right: 7px;
    text-indent: -999px;
    width: 16px;
  }

  .active {
    background-color: $brand-primary;
    border-color: $brand-primary;
  }
}

@media screen and (min-width: 768px) {
  .carousel-indicators {
    li {
      height: 20px;
      width: 20px;
    }
  }
}

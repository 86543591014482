@mixin make-button--primary-1() {
  @include button-huer(
    #fff,
    #fff,
    $brand-primary,
    darken($brand-primary, 3%),
    $brand-primary,
    darken($brand-primary, 5%)
  );

  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  font-size: $font-size-xs;
  letter-spacing: 0;
  padding: 11px 18px;
  text-transform: uppercase;

  .btn-icon {
    fill: #fff;
    margin-right: 3px;
  }

  &:hover,
  &:active {
    .btn-icon {
      fill: #fff;
    }
  }
}

@mixin make-button--primary-2() {
  @include button-huer(
    $brand-primary,
    #fff,
    transparent,
    $brand-primary,
    $brand-primary,
    $brand-primary
  );

  display: inline-block;
  padding: 15px 15px;
  transition: background-color ease-out 0.2s;

  .section-btn-icon svg {
    fill: $brand-primary;
  }

  .section-btn-text {
    font-weight: 400;
  }
}

.breadcrumb {
  padding: 0;

  .breadcrumb-item,
  .breadcrumb-link {
    color: $brand-gray;
    font-family: $font-family-bold;
    font-size: $font-size-sm;

    &:hover {
      color: $brand-gray;
    }
  }

  .breadcrumb-link {
    text-decoration: none;
  }

  .breadcrumb-item--divider .wizicon {
    fill: $brand-gray;
    vertical-align: text-bottom;
  }
}

.breadcrumb--channel {
  display: none;
  padding: 0;
}

.layout-index,
.layout--show {
  .breadcrumb--channel {
    display: flex;
    padding: 0 180px;

    .breadcrumb-item:nth-child(1),
    .breadcrumb-item:nth-child(2) {
      display: none;
    }

    @media screen and (max-width: $screen-md) {
      padding: 0;
    }
  }
}

@import '../../settings/_variables.scss';

div[data-graph-type="bar"] .c3-tooltip-container {
  background-color: $brand-secondary;
  border: 1px solid $brand-primary;
  font-family: $font-family-secondary;
  font-size: $font-size-xxl;
  padding: 10px 18px;
}

div[data-graph-type="donut"] .c3-tooltip-container {
  tr {
    border-color: $brand-primary;
  }

  td {
    border-left-color: $brand-primary;
    font-family: $font-family-secondary;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }
}

// Section / *
.section {
  padding: 58px 0 42px;
}

.section-title {
  position: relative;

  &:before {
    background-color: $brand-primary;
    bottom: -2px;
    content: '';
    position: absolute;
    height: 4px;
    width: 110px;
  }

  a {
    @extend .link-reset; // .link-default ?
  }
}

.section-block {
  padding: 0;
}

.section-header {
  padding-top: 0;
}

.section-footer {
  padding-bottom: 0;
}

.section-composite {
  margin-bottom: $section-spacer-y; // When composite, it does not get the margin.
}

.section-block-form {
  padding-bottom: $section-spacer-y - $form-group-margin-bottom;
}

.section-reset {
  padding-bottom: 0;

  @media screen and (min-width: $screen-sm-min) {
    .section-block {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.section-text--secondary {
  color: $section-text-color-muted;
}

.mask-button {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 100%;

  &::before {
    -webkit-transform: translate(-50%, -50%);
    background: $brand-primary;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

.section-btn {
  @include button-huer(
    $brand-primary, // $color,
    $brand-primary, // $color-hover,
    $brand-secondary, // $bg,
    $brand-secondary, // $bg-hover,
    $brand-primary, // $border-color,
    $brand-primary, // $border-hover,
    $brand-primary, // $color-active: $color,
  );

  display: inline-block;
  letter-spacing: 0;
  margin: 0;
  min-width: 210px;
  padding: 19px 22px 20px;
  position: relative;

  &:focus,
  &:hover,
  &:active,
  &:focus:active,
  &:hover:active {
    background-color: $brand-secondary;
    color: $brand-primary;
  }

  .section-btn-icon {
    display: none;
    vertical-align: middle;

    .wizicon {
      @include size(13px, 13px);
      fill: #fff;
    }
  }

  .section-btn-text {
    font-size: $font-size-base;
    font-weight: unset;
    letter-spacing: 0;
    text-align: center;
    vertical-align: middle;
  }
}

.section--jumbotron-channel {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;

  .section-container {
    padding: 0;
    width: auto;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }

    @media (min-width: $screen-md-min) {
      width: auto;
    }

    @media (min-width: $screen-lg-min) {
      width: auto;
    }

    .section-wrapper {
      > .container {
        padding: 0;
        width: 100%;

        @media screen and (max-width: $screen-md) {
          padding: 0 16px;
        }
      }
    }
  }
}

.section--header {
  padding: 20px 0;
  margin-bottom: 0;

  .section-img {
    margin-bottom: 10px;
  }

  .section-title {
    border: 0;
    margin-bottom: 7px;
    padding: 0;

    font-size: 16px; // @TODO
    font-weight: 600;
    text-transform: none;
    text-align: center;
  }

  .section-text {
    text-align: center;
  }
}

.section--header-2 {
  .section-img {
    > img {
      margin-left: 0;
    }
  }

  .section-title,
  .section-text {
    text-align: left;
  }
}

.section--header-3 {
  .section-title,
  .section-text {
    text-align: left;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .section-img {
      margin-left: 0;
      width: 50%;

      > img {
        margin-left: 0;
      }
    }

    .section-block {
      bottom: 0px;
      position: absolute;
      right: 0;

    }

    .section-title,
    .section-text {
      text-align: right;
    }
  }
}

.section--jumbotron-view {
  .section-container {
    padding: 0 200px;
  }

  padding-bottom: 31px;
  padding-top: 34px;

  .section-title {
    color: $brand-primary;
    font-family: $font-family-bold;
    font-size: $font-size-page-title;
    padding-bottom: 33px;
    position: relative;
    text-align: center;

    &::after {
      background: $brand-primary;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      max-width: 400px;
      position: absolute;
      transform: translate(-50%, 0);
      width: 100%;
    }
  }

  .section-description {
    margin-top: 20px;

    p {
      margin: 0;
    }
  }
}

.layout-index {
  &.layout--folder-index,
  &.layout--photo-index,
  &.layout--video-index {
    .breadcrumb--channel {
      .breadcrumb-item:nth-child(6),
      .breadcrumb-item:nth-child(7) {
        display: none;
      }
    }
  }


  .datetimepicker {
    table {
      tr {
        td {
          span.active.active {
            background: $brand-primary;

            &:hover {
              background: $brand-primary;
            }
          }

          span.active {
            &:hover {
              background: $brand-primary;
            }
          }
        }

        td {
          &.active {
            &.active {
              background: $brand-primary;
            }

            &:active,
            &:hover.active {
              background: $brand-primary;
            }

            &:hover {
              &:active {
                background: $brand-primary;
              }
            }
          }
        }
      }
    }
  }

  .section-footer {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }

  .pagination {
    margin-top: 35px;
    padding-bottom: 0px;
  }

  .section--spokespersons {
    padding-bottom: 58px;
  }

  .section--jumbotron-view {
    padding-top: 49px;
  }

  &.layout--web_publication-index {
    .section--jumbotron-view {
      .section-description {
        display: none;
      }
    }

    .section--news {
      padding-bottom: 70px;

      .section-container {
        padding: 0 184px;
      }

      .gallery.row {
        margin: 0 -40px;
      }

      .pagination {
        margin-top: 35px;
      }
    }
  }

  &.layout--event_web_publication-index {
    .section--jumbotron-view .section--events {
      padding-top: 5px;
    }

    .section--events {
      .section-title {
        color: $brand-primary;
      }
    }
  }

  &.layout-search {
    .section--searchform,
    .section--any {
      .section-container {
        padding-left: 180px;
        padding-right: 180px;
      }
    }

    .section--searchform {
      background-color: transparent;
      border: none;
      margin-top: 0;

      .form--search {
        .row {
          display: flex;
          margin-left: 0;
          margin-right: 0;
        }

        .form-element {
          &[class*="col-"] {
            padding-left: 0;
            padding-right: 0;
            width: auto;
          }

          &.form-element--pattern {
            flex: 4;
          }

          &.form-element--datepicker {
            flex: 2;
            margin-left: 20px;
          }

          &.form-element--submit {
            flex: 1;
            margin-left: 8px;
          }
        }

        .form-element input:focus,
        .form-element input:focus + .input-group-addon {
          border-color: $border-color-dark;
        }

        .input-group-addon {
          border-color: $border-color-dark;
          border-radius: unset;
        }

        .form-control {
          border-color: $border-color-dark;
          border-radius: unset;
          color: $brand-gray;
          font-family: $font-family-primary;
          font-size: 14px;
          height: auto;
          line-height: 16px;
          padding: 18px 0 18px 15px;
        }

        .form-submit {
          background-color: $brand-primary;
          border-radius: unset;
          border: none;
          font-size: $font-size-base;
          height: auto;
          padding: 20px 18px;
          text-transform: unset;
        }

        .wizicon {
          height: 27px;
          width: 27px;
        }
      }
    }

    .section--any {
      padding-top: 20px;

      .section-header {
        margin-bottom: 40px;

        .nav-tabs {
          border: none;
        }

        .nav-underline {
          > li {
            border-bottom: 1px solid $border-color;
            padding-right: 44px;
            margin-bottom: 20px;

            &:last-child {
              padding-right: 0;
            }

            &.active {
              > a {
                &::after {
                  background-color: $brand-primary;
                  height: 5px;
                }
              }
            }
          }

          &.nav-underline-alt {
            > li {
              &.active {
                > a {
                  color: $brand-primary;
                  font-family: $font-family-bold;
                }
              }

              > a {
                color: $text-color;
                font-family: $font-family-primary;
                font-size: $font-size-xl;
                font-weight: unset;
                line-height: $font-size-xxl;
                opacity: unset;
                padding: 11px 11px 11px 0;

                &:hover {
                  &::after {
                    background-color: $brand-primary;
                  }
                }
              }
            }
          }
        }
      }
    }

    .section-composite.section-composite {
      margin-top: 40px;
    }

    .section--experts,
    .section--events {
      .section-header {
        margin-bottom: 0;
      }
    }
  }

  .section--files,
  .section--folders,
  .section--photos,
  .section--videos {
    padding-bottom: 70px;

    .section-container {
      padding: 0 71px;
    }

    .gallery.row {
      margin: 0 -12px;
    }

    .section-block-grid {
      padding-bottom: 25px;

      .gallery-item {
        padding: 0 12px;
      }
    }
  }

  &.layout--asset-index {
    .layout-hook--main {
      .section--odd:not(.section--tags):not(.section--spokespersons) {
        background: $brand-light-gray;

        &.section--jumbotron-view {
          background: $brand-secondary;
        }

        .card--asset,
        .card-block {
          background: $brand-secondary;
        }

        .section-btn {
          background-color: $brand-light-gray;
        }
      }

      .section--even:not(.section--tags):not(.section--spokespersons) {
        .section-title {
          color: $brand-primary;
        }

        .card--asset,
        .card-block {
          background: $brand-light-gray;
        }

        .section-btn {
          background-color: $brand-secondary;
        }
      }

      .section--files {
        padding-bottom: 0;
      }
    }
  }

  &.layout--media_review-index {
    .section--clippings {
      padding-bottom: 70px;

      .pagination {
        margin-top: 35px;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .section--jumbotron-view {
      padding: 32px 0;

      .section-container {
        padding: 0 16px;
      }
    }

    &.layout--event_web_publication-index {
      .section--events {
        .section-container {
          padding: 0 16px;
        }
      }
    }

    &.layout-search {
      .section--searchform,
      .section--any {
        .section-container {
          padding: 0 16px;
        }

        .section-header {
          .nav-tabs {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .form--search {
        .form-body {
          .row {
            flex-direction: column;

            .form-element {
              .input-group {
                margin-right: 0;
              }

              &.form-element--datepicker,
              &.form-element--submit {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    &.layout--web_publication-index .section--news {
      padding: 32px 0;

      .section-container {
        padding: 0 16px;
      }

      .gallery.row {
        margin: 0 -16px;
      }
    }

    .section--files,
    .section--folders,
    .section--photos,
    .section--videos {
      .section-container {
        padding: 0 16px;
      }

      .gallery.row {
        margin: 0 -16px;
      }

      .section-block-grid {
        padding-bottom: 0;

        .gallery-item {
          padding: 0 16px;
        }
      }
    }

    .section--tags.section--highlight {
      .section-block {
        padding: 0;
      }
    }
  }

  &.layout--spokesperson-index {
    .section--spokespersons {
      .section-container {
        padding: 0 137px;
      }

      .gallery {
        &.row {
          margin: 0 -25px;
        }

        .gallery-item[class*="col-"] {
          padding: 0 25px;
        }
      }
    }

    .section--contactform {
      padding-bottom: 55px;
      padding-top: 63px;

      .section-container {
        padding: 0 96px;
      }

      .section-title {
        color: $brand-primary;
      }
    }

    @media screen and (max-width: $screen-md) {
      .section--spokespersons {
        padding-bottom: 32px;

        .section-container {
          padding: 0 16px;
        }

        .gallery {
          &.row {
            margin: 0 -16px;
          }

          .gallery-item[class*="col-"] {
            padding: 0 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .section--contactform {
        padding-bottom: 32px;

        .section-container {
          padding: 0 16px;
        }
      }
    }
  }
}

.section--events {
  padding-bottom: 18px;

  .section-title {
    padding-bottom: 40px;
  }

  &:has(.section-btn) {
    padding-bottom: 40px;
  }

  .gallery.row {
    margin: 0 -16px;

    .gallery-item[class*="col-"] {
      margin-bottom: 40px;
      padding: 0 16px;
    }
  }

  &.section-events-to-come {
    padding-bottom: 8px;

    &::before {
      -webkit-transform: translate(-50%);
      background-color: $brand-light-gray;
      bottom: 0;
      content: "";
      height: 1px;
      left: 50%;
      max-width: 678px;
      position: absolute;
      width: 100%;
    }
  }

  @media screen and (max-width: $screen-md) {
    .gallery.row {
      .gallery-item[class*="col-"] {
        margin-bottom: 35px;
        padding: 0 16px;
      }
    }

    &.section-events-to-come {
      &::before {
        max-width: calc(100% - 32px);
      }
    }
  }
}

.layout-tag {
  .section--jumbotron.section--minus {
    padding: 42px 88px 0;

    .section-wrapper {
      display: flex;
      flex-direction: row;
      padding-bottom: 35px;

      &::after {
        background: $brand-primary;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        max-width: 400px;
        position: absolute;
        transform: translate(-50%, 0);
        width: 100%;
      }
    }

    .section-block {
      flex: 1;

      .section-title {
        color: $brand-primary;
        font-family: $font-family-bold;
        font-size: $font-size-page-title;
        padding-bottom: 0;
        position: relative;
        text-align: center;
      }

      .section-text {
        margin-top: 33px;

        p {
          color: $brand-dark;
          font-family: $font-family-primary;
          font-size: $font-size-md;
          font-weight: $font-weight-light;
          letter-spacing: 0;
          line-height: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media screen and (max-width: $screen-md) {
        .section-title {
          font-size: $font-size-xxxl;
          line-height: 36px;
        }

        .section-text {
          font-size: $font-size-base;
          line-height: 18px;
        }
      }
    }

    .right-block {
      flex-basis: 40%;
      margin-left: 32px;

      .section-img {
        @include image-view(4, 3);
      }

      @media screen and (max-width: $screen-md) {
        display: none;
      }
    }

    @media screen and (max-width: $screen-md) {
      padding: 0;

      .section-img.section-img-mobile {
        @include image-view(4, 3);

        margin-top: 28px;
      }
    }

    @media screen and (min-width: $screen-md) {
      .section-img.section-img-mobile {
        display: none;
      }
    }
  }

  .section--any,
  .section--experts,
  .section--events {
    padding: 32px 88px;

    .section-title {
      color: $brand-dark;
      font-family: $font-family-bold;
      font-size: $font-size-xxxl;
      font-weight: $font-weight-bold;
      letter-spacing: 0;
    }

    .section-footer {
      margin-top: 12px
    }

    @media screen and (max-width: $screen-md) {
      padding: 32px 0;
    }
  }

  .section--any {
    padding-top: 56px;

    .section-block {
      margin-bottom: 0;
    }
  }
}

.section--news.section--highlight {
  padding-bottom: 0;
  padding-top: 43px;

  .section-block {
    padding: 0;
  }
}

.section-header.section-header--with-options {
  @include clearfix();

  padding-bottom: 0;

  .section-header-widget {
    display: none;
  }

  .section-title {
    border-bottom-width: 0;
    overflow: hidden;
  }
}

.section-composite {
  position: relative;
}

.section--boilerplate {
  padding: 40px 0;
  margin-bottom: 0;

  .section-img {
    margin-bottom: 10px;

    img {
      margin: auto;
      display: block;
      width: 200px;
    }
  }

  .section-title {
    @include make-font-10();
    border-bottom: 0;
  }

  .section-block {
    @include make-font-1();
    padding-top: 0;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include make-columns(2, 2em);
    }
  }
}

.section-languages {
  text-align: right;

  .section-header-widget-item {
    display: inline-block;
  }
}

.section--newsletter {
  .form-submit {
    @include make-block-center(25%);
    @include make-button--primary-1();

    @media screen and (max-width: $screen-sm) {
      width: initial;
    }
  }
}

.layout--newsletter-index {
  .section--jumbotron-view {
    .section-description {
      display: none;
    }
  }

  .section--newsletter {
    .section-wrapper {
      margin: 0 auto;
      max-width: 880px;
    }

    .form-group--info {
      display: flex;
      flex-direction: column;
    }
  }

  .form--name,
  .form--email {
    width: 100%;
  }
}

.layout-hook--footer .section--newsletter {
  background-color: $brand-red-dark;
  margin-bottom: 29px;
  padding-bottom: 26px;
  padding-top: 48px;

  .section-title {
    color: $brand-secondary;
    padding-bottom: 52px;
  }

  .form-group {
    margin-bottom: 30px;

    label {
      color: $brand-secondary;
      font-family: $font-family-bold;
      font-size: $font-size-base;
      font-weight: unset;
      line-height: 16px;
      margin-bottom: 0;
    }

    .help-block {
      color: $brand-secondary;
    }

    &.form-group--eula {
      .checkbox {
        margin: 0;

        label {
          padding: 0;
          padding-left: 21px;

          input[type="checkbox"] {
            margin: 0;
            margin-left: -21px;
            margin-top: 5px;
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .form-control {
      border-radius: 5px;
      color: $brand-gray;
      margin-top: 4px;
      padding: 9px 11px;
    }
  }

  .g-recaptcha.form-group--captcha + .form-group {
    margin-bottom: 0;
  }

  .section-btn {
    background: $brand-secondary;
    color: $brand-red-dark;
  }

  .layout--newsletter-index & {
    display: none;
  }
}

.section--newsletter {
  .form-group--info {
    display: flex;
    justify-content: space-between;
  }

  .mask-button {
    &::before {
      content: none;
    }
  }

  .form-group--captcha {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;

    @media screen and (max-width: $screen-md) {
      justify-content: center;
    }
  }

  .section-btn {
    background: $brand-red-dark;
    color: $brand-secondary;
  }

  #form_row_front_newsletter_type_newsletterSubject {
    display: none;
  }

  @media screen and (max-width: $screen-md) {
    .form-group--info {
      flex-direction: column;

      .form--name {
        width: 100%;
      }
    }

    .form-group--captcha,
    .google-captcha-field {
      display: flex;
      justify-content: center;
    }
  }
}

.section--contactform {
  .card--contactform {
    @include make-block-center(880px);

    .section-btn[type="submit"] {
      background: $brand-primary;
      border-radius: 0;
      border: none;
      color: $brand-secondary;
      display: block;
      font-size: $font-size-base;
      line-height: $font-size-md;
      margin: 55px auto 0;
      min-width: unset;
      padding: 20px 22px 19px;

      &:active,
      &:hover {
        background: $brand-primary;
        color: $brand-secondary;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .card--contactform {
      width: 100%;
    }
  }
}

.section--main,
.section--sidebar {
  margin-bottom: 0;
}

.section--jumbotron-content {
  margin: 0;
  padding: 0; // @TODO

  .section-container {
    padding: 0 144px;
  }

  .section-background {
    background-color: $brand-primary;
    display: none;
    height: 270px;
    overflow: hidden;
    position: relative;

    img {
      bottom: -5%;
      filter: url(#ff-blur);
      left: -5%;
      max-width: none;
      position: absolute;
      right: -5%;
      top: -33%;
      width: 110%; // Let the img overflow, since there's some blur on it.
    }
  }

  .section-title {
    @include make-font-title--1();
    padding-bottom: 40px;
    text-align: left;
    word-break: break-word;
  }

  .js-sharing-affix {
    left: -80px;
    position: absolute; // Positioned relative to the section-wrapper.
    top: 180px;
    width: 80px;

    &.affix {
      position: fixed;
    }

    @media screen and (max-width: $screen-md) {
      display: none;
    }
  }

  .section-subtitle {
    @include make-font-subtitle--1();
    margin-bottom: 23px;
    text-align: center;

    .section-subtitle-job {
      font-size: $font-size-xl;
      line-height: 24px;
    }
  }

  .section-element--tags {
    margin-bottom: 0;
    text-align: center;

    .list--tags {
      .list-item {
        display: inline-flex;
      }
    }

    &.alway-first-tag {
      margin-bottom: 14px;

      .list--tags {
        .list-item {
          margin-bottom: 0;

          .label--tags {
            background-color: $brand-red-dark;
            color: $brand-secondary;
          }
        }
      }
    }
  }

  .section-text {
    color: $text-color;
    font-family: $font-family-primary;
    font-size: $font-size-lg;
    line-height: 22px;
  }

  .section-img {
    @include image-view(16, 9);

    max-width: unset;
    width: 100%;
  }

  @media screen and (max-width: $screen-md) {
    .section-container {
      padding: 0 16px;
    }

    .section-mask-line {
      &::before {
        width: 100%;
      }
    }

    .section-title {
      padding: 0 0 40px;
    }
  }

  &.section--jumbotron-web_publication {
    .section-text {
      font-size: $font-size-xl;
      line-height: 24px;
    }
  }
}

.section--jumbotron-event {
  .mediaobj--eventinfo {
    .mediaobj-body {
      color: $text-color;
      font-size: $font-size-md;
      line-height: 16px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      text-align: center;

      .mediaobj-object {
        margin-bottom: 13px;
      }

      .mediaobj-body {
        text-align: center;
      }
    }
  }
}

.section--jumbotron-expert {
  &.section--jumbotron-content {
    .section-title {
      text-align: center;
    }

    .section-text {
      font-family: $font-family-primary;
      font-weight: $font-weight-regular;
    }

    .section-subtitle-profile {
      color: $brand-dark;
      font-family: $font-family-bold;
      font-size: $font-size-section-title;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
}

.section--searchform {
  background-color: $brand-light;
  border: 1px solid $border-color;
  margin-bottom: 0;
  padding: 0;

  .section-block {
    padding-bottom: $section-spacer-y;
    padding-top: $section-spacer-y;
  }
}

.section--default.section--webidentities {
  .section-header {
    padding-bottom: 0;
  }

  .section-title {
    border-bottom: 0;
    font-size: $font-size-lg;
    text-align: center;
  }

  .list--webidentities {
    text-align: center;
  }
}

.section--webidentities {
  &.section--highlight,
  &.section--webidentities-highlight-mobile {
    .list--webidentities {
      display: flex;
      margin: 0 -5px;

      .list-item {
        margin: 0 0 5px;
        padding: 0 5px;

        .webidentity {
          &:hover,
          &:focus {
            border: 0;
            outline: unset;
          }
        }
      }
    }
  }
}

.section--wiztrust_alt {
  background-color: $brand-light-gray;
  padding: 60px 0;

  .section-container {
    padding: 0 16px;
    max-width: 780px;
  }

  .section-block {
    margin: auto;
    width: 100%;

    .section-wiz {
      align-items: center;
      display: flex;

      .fingerprint {
        margin-right: 15px;

        svg {
          width: 50px;
          height: 63px;
        }
      }

      .section-wiz-text {
        .content-text {
          font-size: $font-size-md;
          line-height: 17px;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    padding: 32px 0;
  }
}

.section--powered-by {
  padding: 0;

  .section-container {
    padding: 0;
    width: 100%;
  }

  .section-block {
    padding: 24px 60px;
    width: auto;
  }

  .section-text {
    a {
      p, span {
        display: inline-block;
        vertical-align: bottom;
      }

      p {
        font-size: 12px;
        font-family: $font-family-secondary;
        margin: 0 5px 0 0;
        text-decoration: underline;
      }

      span {
        width: 90px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.section-wrapper .section--features--generic {
  position: absolute;
  right: -80px;
  top: 180px;
  width: 80px;

  .card--feature {
    margin-bottom: 10px;
    text-align: center;

    .card-img {
      float: none;
      margin: auto;
      margin-bottom: $card-spacer-y;
      width: initial;

      img {
        @include size(45px, 45px);
      }
    }

    .card-title {
      color: $brand-primary;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      position: static;
      text-transform: uppercase;
      transform: initial;
    }
  }

  @media screen and (max-width: $grid-float-breakpoint) {
    display: none;
  }
}

.layout--press-review-index {
  .section--powered-by {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.layout-content .section--features,
.layout-event .section--features {
  .section-title-icon {
    display: none;
  }

  .card--feature {
    .card-block {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .card-img {
      margin: 0;
      margin-right: $card-spacer-x;
      width: 20%;

      img {
        @include size(45px, 45px);
      }
    }

    .card-title {
      width: 80%;
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

.layout-tag {
  .section--any,
  .section--experts,
  .section--events {
    .section-title {
      font-size: $font-size-xl;
    }
  }

  .section--jumbotron.section--minus {
    .section-img {
      margin-bottom: 15px;
    }
  }
}

.content-text--blockchain a {
  text-decoration: underline;
}

.section--tags {
  &.section--highlight,
  &.section--tags-highlight-mobile {
    background: $brand-primary;
    padding: 34px 0 14px;

    .section-container {
      width: 100%;
    }

    .section-title {
      color: $brand-secondary;
      padding-bottom: 36px;
      pointer-events: none;

      .section-title-icon {
        display: none;
      }
    }

    .section-block {
      padding: 0 135px;

      .list--tags {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -5px;

        .list-item {
          display: inline-flex;
          margin: 0 5px 28px;

          .label--tags {
            background-color: $brand-secondary;
            border-color: $brand-secondary;
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      .section-block {
        .list--tags {
          justify-content: flex-start;
        }
      }
    }
  }

  &.section--tags-highlight-mobile {
    .section-block {
      padding: 0;
    }
  }
}

.layout-hook--footer {
  .section.section--footer {
    padding: 0;

    .section-container {
      padding: 0;

      .section--corporate {
        align-items: flex-end;
        background-color: $brand-secondary;
        border-top: 2px solid $brand-primary;
        display: flex;
        padding: 29px 34px 84px;

        @media (max-width: $screen-sm) {
          padding-bottom: 100px;
        }

        .footer-block {
          flex: 1;
          padding-left: 67px;

          .nav-section-corporate {
            display: flex;
            line-height: 0;
            list-style: none;
            margin: 0 -37px;
            padding: 0;

            .nav-item {
              padding: 0 37px;

              &:last-child {
                -webkit-transform: translate(-50%, 0);
                bottom: 25px;
                left: 50%;
                position: absolute;
                text-align: center;
              }

              a.nav-link {
                color: $brand-dark;
                font-family: $font-family-bold;
                font-size: $font-size-md;
                line-height: 16px;
                text-transform: uppercase;
              }
            }
          }
        }

        .section-img {
          display: inline-block;
          flex-basis: 283px;

          img {
            max-height: 39px;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .section.section.section--footer {
      .section-container {
        .section--corporate {
          align-items: flex-start;
          flex-direction: column-reverse;
          padding-left: 16px;
          padding-right: 16px;

          .section-img {
            flex-basis: unset;
            margin-bottom: 30px;
          }

          .footer-block {
            padding-left: 0;

            .nav-section-corporate {
              flex-direction: column;

              .nav-item:not(:last-child) {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.section--experts {
  .gallery.row {
    margin: 0 -22px;

    .gallery-item[class*="col-"] {
      padding: 0 22px;
    }
  }

  @media screen and (max-width: $screen-md) {
    .gallery.row {
      margin: 0 -16px;

      .gallery-item[class*="col-"] {
        padding: 0 16px;
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .section {
    padding: 32px 0;
  }

  .section-container {
    padding: 0 16px;
    width: 100%;
  }

  .row {
    margin: 0 -16px;

    *[class*="col-"] {
      padding: 0 16px;
    }
  }
}

.med-nav-access {
  background-color: $brand-primary;
  border-radius: 4px;
  color: $brand-white;
  cursor: pointer;
  display: none;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  left: 0;
  padding: 12px 24px;
  position: absolute;
  top: 0;
  transition: all .2s cubic-bezier(.2,0,0,1);
  z-index: 2;

  &:hover {
    color: $brand-white;
  }

  &:focus {
    color: $brand-white;
    z-index: 1081;
  }
}

.screen-reader-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.list {
  @include list-unstyled();
}

.list--horizontal {
  @include list-component(10px);
}

.list--vertical {
  margin-left: -5px;

  .list-item {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 7px;
  }
}

.list--tags {
  display: inline-block;
  font-size: $font-size-base;
  margin: 0 -10.5px;

  .list-item {
    display: inline-flex;
    margin: 0 10.5px 10px;

    &:nth-child(2) {
      margin-right: 0;
    }

    &:nth-child(n+3) {
      display: none;
    }

    .label--tags {
      background: transparent;
      border-radius: 5px;
      border: 1px solid $brand-primary;
      color: $brand-primary;
      font-size: $font-size-base;
      font-weight: unset;
      padding: 4px 8px;
      text-transform: none;

      &[href]:active,
      &[href]:focus,
      &[href]:hover {
        background: transparent;
        border: 1px solid $brand-primary;
        color: $brand-primary;
      }
    }
  }
}

.list--share {
  @extend .list--vertical;
}

.list--assets {
  .list-item:first-child {
    .list-item-element {
      border-top-width: 0;
    }
  }

  .list-item-link {
    @extend .link-default;

    font-weight: 600;
  }

  .list-header-element {
    border-bottom-width: 0;
    color: $text-muted;
    font-family: $font-family-primary;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .list-item:nth-child(even) .list-item-element {
    background-color: $brand-light-lter;
  }

  .list-item:nth-child(odd) .list-item-element {
    background-color: $brand-light-dk;
    border-right: 1px solid $brand-secondary;
  }

  .list-item .list-item-element .wizicon {
    fill: $brand-primary;
  }

  .list-item-element {
    border-top-width: 0;
    padding: 12px 15px;
    vertical-align: middle;
  }

  .list-item-element--type {
    .wizicon {
      @include size(30px, 30px);
      fill: $brand-dark-lter;
    }
  }

  .list-item-element--title {
    font-weight: 600;
  }

  .list-item-element--tags {
    .list--tags {
      margin-bottom: 0;
    }
  }

  .list-item-element--date {
    white-space: nowrap;
  }
}

.list--webidentities {
  @extend .list--vertical;
}

.list--content-download-1 {
  @include list-unstyled();

  margin-bottom: 0;
  width: 100%;

  .list-item {
    background-color: $brand-primary;
    padding: 10px 15px;
    text-align: center;

    a {
      color: $brand-secondary;
    }
  }

  .list-item {
    border-top: 1px solid $border-color;
  }
}

.list--content-download-2 {
  @media screen and (min-width: $grid-float-breakpoint) {
    @include make-grid-layout($grid-gutter-width / 2, '.list-item');

    .list-item {
      width: 50%;
    }

    .btn {
      display: block;
    }
  }

  .list-item {
    margin-bottom: 16px;
  }

  .btn--secondary {
    background-color: $brand-primary;
    color: $brand-secondary;
    display: flex;
    justify-content: center;

    &:active {
      background-color: $brand-primary;
      color: $brand-secondary;
    }

    .section-btn-icon {
      margin-right: 14px;
    }
  }
}

.list--event_web_publication-anchors {
  @include list-unstyled();
  text-align: center;

  .list-item {
    margin-bottom: 20px;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .list-item {
      display: inline-block;

    }

    .list-item + .list-item {
      margin-left: 20px;
    }
  }
}

.list--event_web_publication--properties {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0;
  padding: 50px 85px;
  width: 100%;

  .list-item {
    &:nth-child(2) {
      margin-left: auto;
    }

    .mediaobj--eventinfo {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > .mediaobj-object {
        .wizicon {
          color: $brand-primary;
          fill: $brand-primary;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;

    .list-item {
      margin-bottom: 10px;

      &:nth-child(2) {
        margin-bottom: 0;
        margin-left: 0;
      }

      .mediaobj--eventinfo {
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}

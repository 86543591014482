@font-face {
    font-family: "Roboto-Black";
    font-style: normal;
    src: url('../../../fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-BlackItalic";
    font-style: normal;
    src: url('../../../fonts/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-Bold";
    font-style: normal;
    src: url('../../../fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-BoldItalic";
    font-style: normal;
    src: url('../../../fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-Italic";
    font-style: normal;
    src: url('../../../fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-Light";
    font-style: normal;
    src: url('../../../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-LightItalic";
    font-style: normal;
    src: url('../../../fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-Medium";
    font-style: normal;
    src: url('../../../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-MediumItalic";
    font-style: normal;
    src: url('../../../fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-Regular";
    font-style: normal;
    src: url('../../../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-Thin";
    font-style: normal;
    src: url('../../../fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-ThinItalic";
    font-style: normal;
    src: url('../../../fonts/Roboto-ThinItalic.ttf') format('truetype');
}

@mixin make-font-title--1() {
  color: $text-color;
  font-family: $font-family-bold;
  font-size: $font-size-xxxl;
  line-height: 32px;
}

@mixin make-font-title--2() {
  color: $text-color;
  font-family: $font-family-primary;
  font-size: $font-size-xl;
  font-weight: 700;
}

@mixin make-font-title--4() {
  color: $text-color;
  font-family: $font-family-primary;
  font-size: 18px;
  font-weight: 700;
}

@mixin make-font-subtitle--1() {
  color: $text-color;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  line-height: 16px;
}

@mixin make-font-subtitle--2() {
  color: $text-color;
  font-family: $font-family-primary;
  font-size: $font-size-sm;
  font-weight: 400;
  text-transform: uppercase;
}

@mixin make-font-text--primary-highlight() {
  color: $text-color;
  font-family: $font-family-primary;
  font-size: $font-size-xl;
  line-height: 24px;
}

@mixin make-font-text--primary() {
  color: $text-muted;
  font-family: $font-family-secondary;
  font-size: $font-size-md;
  line-height: 1.7;
}

@mixin make-link-default() {
  &,
  &:focus,
  &:hover {
    color: inherit;
  }
}

@mixin make-link-reset() {
  &,
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}


@mixin make-font-1() {
  $mobile-font-size-base: $font-size-base * 1.21;

  color:       $brand-dark;
  font-family: $font-family-secondary;
  font-size:   $font-size-sm;
  font-weight: unset;

  // @media screen and (max-width: $grid-float-breakpoint) {
  //   font-size: 17px;
  // }
}

@mixin make-font-2() {
  $mobile-font-size-base: $font-size-lg * 1.21;

  color:       $text-color;
  font-family: $font-family-secondary;
  font-size:   $font-size-xl;
  font-weight: unset;
  line-height: 24px;

  // @media screen and (max-width: $grid-float-breakpoint) {
  //   font-size: $mobile-font-size-base;
  // }
}

@mixin make-font-3() {
  $mobile-font-size-base: $font-size-xl * 1.21;

  color:       $brand-dark;
  font-family: $font-family-secondary;
  font-size:   $font-size-lg;
  font-weight: $font-weight-regular;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xl;
  // }
}

@mixin make-font-4() {
  $mobile-font-size-base: $font-size-xl * 1.21;

  color:       $brand-dark;
  font-family: $font-family-secondary;
  font-size:   $font-size-xl;
  font-weight: $font-weight-bold;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xl;
  // }
}

@mixin make-font-5() {
  $mobile-font-size-base: $font-size-xl * 1.21;

  color:       $brand-dark;
  font-family: $font-family-secondary;
  font-size:   $font-size-xxl;
  font-weight: $font-weight-bold;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xl;
  // }
}

@mixin make-font-6() {
  $mobile-font-size-base: $font-size-xl * 1.21;

  color:       $brand-dark;
  font-family: $font-family-secondary;
  font-size:   $font-size-xxl;
  font-weight: $font-weight-bold;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xl;
  // }
}

@mixin make-font-7() {
  $mobile-font-size-base: $font-size-xs * 1.21;

  color:          $brand-secondary;
  font-family:    $font-family-primary;
  font-size:      $font-size-xxs;
  font-weight:    $font-weight-regular;
  text-transform: uppercase;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: 10px;
  // }
}

@mixin make-font-8() {
  $mobile-font-size-base: $font-size-xs * 1.21;

  color:          $brand-dark;
  font-family:    $font-family-primary;
  font-size:      $font-size-base;
  line-height: 16px;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xs;
  // }
}

@mixin make-font-9() {
  $mobile-font-size-base: $font-size-lg * 1.21;

  color:          $brand-primary;
  font-family:    $font-family-primary;
  font-size:      $font-size-base;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-lg;
  // }
}

@mixin make-font-10() {
  $mobile-font-size-base: $font-size-lg * 1.21;

  color:          $brand-dark;
  font-family:    $font-family-primary;
  font-size:      $font-size-md;
  font-weight:    $font-weight-bold;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-lg;
  // }
}

@mixin make-font-11() {
  $mobile-font-size-base: $font-size-xl * 1.21;

  color:          $brand-dark;
  font-family:    $font-family-primary;
  font-size:      $font-size-lg;
  font-weight:    $font-weight-regular;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xl;
  // }
}

@mixin make-font-12() {
  $mobile-font-size-base: $font-size-xl * 1.21;

  color:          $brand-dark;
  font-family:    $font-family-primary;
  font-size:      $font-size-xl;
  font-weight:    $font-weight-bold;
  text-transform:  uppercase;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xl;
  // }
}

@mixin make-font-13() {
  color:          $brand-dark;
  font-family:    $font-family-primary;
  font-size:      $font-size-xxl;
  font-weight:    $font-weight-bold;
  text-transform: uppercase;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size-xxl;
  // }
}

@mixin font-wildcard($color, $font-family, $font-size, $font-weight) {
  $mobile-font-size: $font-size * 1.21;
  color: $color;
  font-family: $font-family;
  font-size: $mobile-font-size;
  font-weight: $font-weight;

  // @media screen and (min-width: $grid-float-breakpoint) {
  //   font-size: $font-size;
  // }
}

/**
 * Cross-browser break-word / hyphenate long words.
 *
 * https://css-tricks.com/almanac/properties/h/hyphenate/
 */
@mixin break-word() {
  /* Old IE support */
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

   /* Modern browsers only */
  hyphens: auto;
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin link-decorated() {
  font-size: $font-size-sm;
  font-weight: 400;
  letter-spacing: 0.22em;
  position: relative;
  text-transform: uppercase;

  &:before {
    background-color: $brand-primary;
    content: '';
    display: inline-block;
    margin-right: 15px;
    height: 1px;
    vertical-align: middle;
    width: 25px;
  }
}

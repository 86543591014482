.offie-dropdown {
  min-width: 160px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  z-index: 10;

  &.dropdown-menu {
    display: block; // fix compatibility with dropdown-menu from bootstrap
  }

  &.offie--active {
    opacity: 1;
    transition-delay: 0s; // For visibility to kick-out: http://www.greywyvern.com/?post=337
    visibility: visible;
  }

  .offie-block {
    background-color: $brand-primary;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07);
    margin: 0;

    .nav {
      > .nav-item {
        .nav-link {
          border-color: $brand-primary;
          color: $brand-secondary;
          font-family: $font-family-bold;
          font-size: $font-size-base;
          line-height: 16px;
        }
      }
    }
  }

  .nav-link {
    white-space: nowrap;
  }
}

.offie-collapse {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.25s;
  visibility: hidden;

  &.offie--active {
    opacity: 1;
    visibility: visible;
  }
}

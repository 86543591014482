.layout-event {
  .section--live {
    .section-block {
      max-width: 600px; // dirty fix for embed-container
      margin: 0 auto;
    }
  }

  .section--details {
    .section-header + .section-composite.section--composite,
    .section--details_text {
      margin-bottom: 0;
    }
  }

  .section--map {
    margin-top: $section-spacer-y;
    margin-bottom: 0;
  }
}

.section-block {
  .gallery {
    .gallery-item {
      &:last-child {
        .card--event {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

// Brand
$brand-primary:      #C12017;
$brand-secondary:    #ffffff;
$brand-white:        #ffffff;
$brand-light:        #f6f7fd;
$brand-dark:         #000000;
$brand-gray:         #6F7072;
$brand-light-gray:   #EEEEEE;
$brand-red-dark:     #AA1B17;
$brand-red-lightdark:#8E1230;
$brand-redgrey:      #742127;
$brand-orange:       #E8573D;
$brand-light-orange: #C11A16;
$brand-grey:         #ECECEC;
$brand-dark-grey:    #B3B3B3;
$brand-mobile-menu:  #434444;

$border-color:       #D8D8D8;
$border-color-dark:  #979797;

// Type
$font-family-primary: 'Roboto-Regular', Helvetica, Arial, sans-serif;
$font-family-secondary: 'Roboto-Regular', serif;
$font-size-base: 14px;
$font-family-black: "Roboto-Black";
$font-family-bold: "Roboto-Bold";
$font-family-italic: "Roboto-Italic";
$font-family-medium: "Roboto-Medium";
$font-family-light: "Roboto-Light";
$font-family-thin: "Roboto-Thin";

$font-size-xs: $font-size-base * 0.75; // ~ 10.5px
$font-size-sm: $font-size-base * 0.875; // ~ 12px
$font-size-md: $font-size-base * 1.15; // ~ 16px
$font-size-lg: $font-size-base * 1.29; // ~ 18px
$font-size-xlg: $font-size-base * 1.43; // ~ 20px
$font-size-xl: $font-size-base * 1.575; // ~ 22px
$font-size-xxl: $font-size-base * 1.715; // ~ 24px
$font-size-xxxl: $font-size-base * 2; // ~ 28px
$font-size-section-title: $font-size-base * 2.715; // ~ 38px
$font-size-page-title: $font-size-base * 2.715; // ~ 38px


$font-weight-extra-bold:    900;
$font-weight-bold:          700;
$font-weight-regular:       400;
$font-weight-light:         300;

$line-height-computed: $font-size-base * 1.42;

$text-color: #000000;
$text-muted: #7E8083;
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10%);

$container-sm: 640px;
$container-md: 860px;
$container-lg: 1440px;

$grid-gutter-width: 192px;

// Buttons
$btn-border-radius-base: 0;
$btn-font-family:       $font-family-primary;
$btn-font-size:         14px;
$btn-font-weight:       unset;
$btn-primary-border:    $brand-primary;

// Cards
$card-default-background-color: transparent;
$card-border-color: #eee;
$card-border-width: 0;
$card-box-shadow: none;
$card-btn-prototype: '.link';
$card-link-font-weight: 400;
$card-spacer-x: 12px;
$card-spacer-y: 12px;

// Features.
$feature-icon-width: 40px;
$feature-icon-border-color: transparent;

// Nav
$navbar-client-height:           117px;

// Sections
$section-bg: transparent;
$section-block-bg: none;
$section-block-position: relative;
$section-btn-prototype: '.btn-default';
$section-cap-x: 0px;
$section-cap-y: 20px;
$section-header-bg: none;
$section-footer-bg: none;
$section-margin: 0;
$section-position: relative;
$section-prototype: 'container';
$section-hero-text-color: #fff;
$section-spacer-x: 0px;
$section-spacer-y: 30px;
$section-text-color: $text-color; // .section-text {} font color.
$section-text-color-muted: $text-muted; // .section-text--secondary {}
$section-title-border: 1px solid #eee;
$section-title-font-family: $font-family-bold;
$section-title-font-size: $font-size-section-title;
$section-title-font-weight: 700;
$section-title-line-height: 40px;
$section-title-text-align: left;

// Webidentities
$webidentity-width: 30px;

// RS colors
$brand-dailymotion:     #13B1FC;
$brand-facebook:        #3F51B5;
$brand-flickr:          #ff0084;
$brand-googleplus:      #dd4b39;
$brand-instagram:       #03A9F4;
$brand-linkedin:        #0288D1;
$brand-pinterest:       #bd081c;
$brand-rss:             #f26522;
$brand-tiktok:          #000000;
$brand-twitter:         #000000;
$brand-viadeo:          #f07355;
$brand-youtube:         #FF3D00;
$brand-sharing:         #ED4D4D;
$brand-soundcloud:      #ff8800;
$brand-email:           $brand-primary;

$webidentities: (
  'dailymotion': (
    'bg-color': $brand-dailymotion,
    'border-color': $brand-dailymotion,
    'color': #fff,
  ),
  'facebook': (
    'bg-color': $brand-facebook,
    'border-color': $brand-facebook,
    'color': #fff,
  ),
  'flickr': (
    'bg-color': $brand-flickr,
    'border-color': $brand-flickr,
    'color': #fff,
  ),
  'google-plus': (
    'bg-color': $brand-googleplus,
    'border-color': $brand-googleplus,
    'color': #fff,
  ),
  'instagram': (
    'bg-color': $brand-instagram,
    'border-color': $brand-instagram,
    'color': #fff,
  ),
  'linkedin': (
    'bg-color': $brand-linkedin,
    'border-color': $brand-linkedin,
    'color': #fff,
  ),
  'pinterest': (
    'bg-color': $brand-pinterest,
    'border-color': $brand-pinterest,
    'color': #fff,
  ),
  'rss': (
    'bg-color': $brand-rss,
    'border-color': $brand-rss,
    'color': #fff,
  ),
  'rss-two': (
    'bg-color': $brand-rss,
    'border-color': $brand-rss,
    'color': #fff,
  ),
  'soundcloud': (
    'bg-color': $brand-soundcloud,
    'border-color': $brand-soundcloud,
    'color': #fff,
  ),
  'tiktok': (
    'bg-color': $brand-tiktok,
    'border-color': $brand-tiktok,
    'color': #fff,
  ),
  'twitter': (
    'bg-color': $brand-twitter,
    'border-color': $brand-twitter,
    'color': #fff,
  ),
  'viadeo': (
    'bg-color': $brand-viadeo,
    'border-color': $brand-viadeo,
    'color': #fff,
  ),
  'youtube': (
    'bg-color': $brand-youtube,
    'border-color': $brand-youtube,
    'color': #fff,
  ),
);

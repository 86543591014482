.svgfilters {
  display: none; // hide the .svgfilters element, filters are referenced via CSS.
}

.layout-hook {
  @media (min-width: 1200px) {
    margin: 0 auto;
    max-width: $container-lg;
    overflow: hidden;
    width: 100%;
  }

  &.layout-hook--header {
    overflow: visible;
  }
}

.layout--channel-show {
  .section-container {
    width: 100%;
  }
}

.layout-hook.layout-hook--content {
  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

.layout--show {
  .layout-hook--main {
    padding-top: 30px;

    .section--jumbotron-content.section--odd {
      // 1 is odd, first is always white!
      background-color: #fff;
    }

    .section--even {
      background-color: $brand-light-gray;

      .section-title {
        color: $brand-dark;
      }

      .card,
      .card-block {
        background: $brand-secondary;
      }

      &.section--clippings {
        .card,
        .card-block {
          background: $brand-light-gray;
        }
      }

      &.section--events {
        .card,
        .card-block {
          background: $brand-secondary;
        }
      }
    }

    .section--odd {
      background-color: $brand-secondary;

      .section-title {
        color: $brand-primary;
      }

      .card,
      .card-block {
        background: $brand-light-gray;
      }

      &.section--events,
      &.section--clippings {
        .card,
        .card-block {
          background: $brand-secondary;
        }
      }
    }

    .section--jumbotron-content {
      .section-mask-line {
        .section-title {
          color: $text-color;
        }
      }
    }

    .layout-hook--content {
      background-color: $brand-secondary;

      .section-text {
        padding-top: 28px;
      }

      .section-wrapper {
        margin: 0 auto;
        padding: 0 48px;
      }

      @media screen and (max-width: $screen-md) {
        .section-wrapper {
          padding: 0;
        }
      }
    }
  }

  &.layout--web_publication-show,
  &.layout--expert-show {
    .layout-hook--content {
      .section-container {
        padding: 0 144px;

        .section-wrapper {
          padding: 0;
        }
      }

      @media screen and (max-width: $screen-md) {
        .section-container {
          padding: 0 16px;
        }
      }
    }
  }

  &.layout--event_web_publication-show {
    .layout-hook--main {
      .section--jumbotron-content {
        padding-bottom: 70px;

        &.section--odd {
          background: $brand-secondary;
        }

        .section-container {
          padding: 0 331px;
        }

        .section-title {
          margin-left: -44px;
          margin-right: -44px;
          padding: 0 0 40px;
          text-align: center;
        }

        .section-wrapper {
          margin: 0 auto;
          padding: 0;

          .section-mask-line {
            margin-bottom: 89px;
          }
        }

        .section-element--properties {
          align-items: center;
          background-color: $brand-light-gray;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 auto;

          .section-text--state {
            background-color: $brand-primary;
            color: $brand-secondary;
            font-size: $font-size-md;
            line-height: 16px;
            margin: 0;
            padding: 37px;
            text-align: center;
            width: 100%;
          }

          & ~ .section-subtitle {
            margin-top: 20px;
            margin-bottom: 0;
          }
        }

        .js-sharing-affix {
          display: none;
        }
      }

      @media screen and (max-width: $screen-md) {
        .section--jumbotron-content {
          padding-bottom: 40px;

          .section-container {
            padding: 0 16px;
          }

          .section-wrapper {
            max-width: 100%;
          }

          .section-title {
            margin: 0;
          }

          .section-element--properties {
            width: 100%;
          }
        }
      }

      .section--event_web_publication-content {
        .section-wrapper {
          margin-left: auto;
          margin-right: auto;
        }

        &.section--event-description {
          .section-container {
            padding: 0 331px;
          }

          @media screen and (max-width: $screen-md) {
            .section-container {
              padding: 0 16px;
            }
          }
        }
      }

      .section--details {
        .section-wrapper {
          max-width: 100%;

          .section-title {
            font-size: $font-size-xxxl;
            line-height: 32px;
            padding-bottom: 38px;
          }

          .section--details-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 94px;

            .mediaobj--eventinfo {
              align-items: center;
              display: flex;
              max-width: 50%;

              .mediaobj-object {
                margin-right: 25px;

                .wizicon {
                  color: $brand-primary;
                  fill: $brand-primary;
                }
              }

              .mediaobj-title {
                display: none;
              }

              .mediaobj-body {
                padding: 0;

                p {
                  margin: 0;
                }
              }
            }
          }
        }

        @media screen and (max-width: $screen-md) {
          .section-wrapper {
            .section--details-info {
              padding: 0;
            }
          }
        }

        @media screen and (max-width: 460px) {
          .section-wrapper {
            .section--details-info {
              flex-direction: column-reverse;
              margin-bottom: 0;

              .mediaobj--eventinfo {
                margin: 10px;
                max-width: 100%;
              }
            }
          }
        }
      }

      .section--odd {
        background-color: $brand-light-gray;

        .section-title {
          color: $brand-dark;
        }

        .card,
        .card-block {
          background: $brand-secondary;
        }

        &.section--clippings {
          .card,
          .card-block {
            background: $brand-light-gray;
          }
        }
      }

      .section--even {
        background-color: $brand-secondary;

        .section-title {
          color: $brand-primary;
        }

        .card,
        .card-block {
          background: $brand-light-gray;
        }

        &.section--clippings {
          .card,
          .card-block {
            background: $brand-secondary;
          }
        }
      }

      .section--experts {
        background-color: $brand-secondary;

        .card,
        .card-block {
          background: $brand-secondary;
        }
      }
    }
  }

  &.layout--asset-show,
  &.layout--folder-show,
  &.layout-video,
  &.layout-photo {
    .layout-hook--content {
      padding-bottom: 66px;

      .section-wrapper {
        margin: 0 auto;
        max-width: unset;
        padding: 0 234px;

        .section--content {
          .section-text {
            padding-top: 0;
          }
        }
      }
    }

    .section--jumbotron-content {
      .section-title {
        text-align: center;
      }
    }

    .layout-hook--content {
      .section--content {
        .section-element--download {
          margin-top: -17px;
        }
      }
    }
  }

  &.layout--folder-show {
    .section--jumbotron-content {
      .section-subtitle {
        display: none;
      }

      .share--default {
        display: none;
      }
    }

    .layout-hook--content {
      padding-bottom: 0;
      padding-top: 0;
    }

    .section--assets {
      padding-top: 28px;
    }

    .section--news {
      background-color: $brand-secondary;

      .section-title {
        color: $brand-primary;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    &.layout--asset-show,
    &.layout--folder-show,
    &.layout-video,
    &.layout-photo {
      .layout-hook--content {
        .section-wrapper {
          max-width: 100%;
          padding: 0;
        }
      }
    }
  }

  &.layout--expert-show {
    .section--jumbotron-content {
      .section-wrapper {
        margin: 0 auto;

        .section-mask-line {
          padding-left: 187px;
          padding-right: 187px;
        }

        .section-img {
          height: fit-content;
          margin: 0 auto 96px;
          max-width: 782.46px;
          padding-top: 0;
          width: 100%;

          img {
            position: relative;
          }
        }

        @media screen and (max-width: $screen-lg) {
          .section-mask-line {
            padding-left: 0;
            padding-right: 0;
          }

          .section-img {
            img {
              padding: 0;
            }
          }
        }

        @media screen and (max-width: $screen-md) {
          .section-img {
            margin-bottom: 50px;
          }
        }
      }
    }

    .layout-hook--content {
      .section--expertises {
        display: none;
      }
    }
  }

  .section-mask-line {
    margin-bottom: 40px;
    padding-bottom: 30px;
    position: relative;

    &::before {
      content: "";
      -webkit-transform: translate(-50%, 0);
      background: $brand-primary;
      bottom: 0;
      height: 1px;
      left: 50%;
      position: absolute;
      width: 400px;
    }

    @media screen and (max-width: $screen-md) {
      &::before {
        width: 100%;
      }
    }
  }
}

.content-text {
  // Base for vertical rythm: everything will be a multiple of this.
  $font-size: $font-size-lg;
  $line-height: 1.1 * $font-size;

  // Setup base style.
  font-size: $font-size;
  line-height: $line-height;

  a,
  a:active,
  a:focus,
  a:hover {
    color: $brand-primary;
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 h6,
  .h6 {
    line-height: 1.25;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 28px;
    margin-bottom: 0.75 * $line-height;
    margin-top: 2 * $line-height;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 0.5 * $line-height;
    margin-top: 1.5 * $line-height;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 0.5 * $line-height;
    margin-top: 1.25 * $line-height;
  }

  // Handle special-case when two titles are following each other.
  h2+h2 {
    margin-top: 0.75 * $line-height;
  }

  h2+h3,
  h3+h3,
  h3+h4,
  h4+h4 {
    margin-top: 0.5 * $line-height;
  }

  p {
    margin-bottom: 16px;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  // Type ?
  h2,
  h3 {
    font-weight: 400;
  }

  table td,
  table th {
    padding: 5px 10px;
    margin-bottom: $line-height / 2;
  }

  video {
    width: 100%;
  }

  .embed-container {
    margin-bottom: 55px;

    &.embed-container--twitter {
      margin: 0 auto;
      padding-top: unset;
      width: 320px;
    }
  }

  .table-responsive {
    margin-bottom: $line-height / 2;
  }
}

.pagination {
  align-items: center;
  display: flex;
  padding-bottom: 18px;

  > li {
    display: inline-block;

    & + li {
      margin-left: 5px;
    }

    > a,
    > span {
      border: none;
      line-height: 0;
      padding: 0;

      &:hover,
      &:focus {
        background: transparent;
        color: $brand-dark;
      }
    }

    &.active {
      span {
        color: $brand-dark;
        font-size: $font-size-md;

        &:hover,
        &:focus {
          color: $brand-dark;
        }
      }
    }
  }
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover {
  color: #777 !important;
  cursor: not-allowed;
}

.section-footer {
  .pagination {
    .pagination-link {
      .wizicon {
        fill: $brand-primary;
        height: 27px;
        width: 27px;
      }
    }
  }
}

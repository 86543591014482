.form--name {
  float: left;
  width: 100%;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-right: 0;
    width: 45%;
  }

  @media screen and (min-width: $screen-md) {
    width: 21%;
  }
}

.form--email {
  display: inline-block;
  width: 100%;

  @media screen and (min-width: $grid-float-breakpoint) {
    width: 100%;
  }

  @media screen and (min-width: $screen-md) {
    width: 32.5%;
  }
}

.form-control {
  border: 1px solid $border-color;
}

.form-group--captcha {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 55px;

  @media screen and (max-width: $screen-md) {
    justify-content: center;
  }
}

.form-group {
  margin-bottom: 27px;

  &[id*="type_question"] {
    margin-bottom: 42px;
  }

  &.form-group--eula:not(:last-child) {
    margin-bottom: 15px
  }

  label {
    color: $brand-dark;
    font-family: $font-family-bold;
    font-size: $font-size-xl;
    line-height: $font-size-xxl;
  }

  input.form-control {
    color: $brand-gray;
    padding: 16px 11px;
  }

  .checkbox {
    margin-bottom: 15px;
    margin-top: 0;

    &:last-child {
      margin-bottom: 4px;
    }

    label {
      font-size: $font-size-base;
      line-height: $font-size-md;

      input[type="checkbox"] {
        & + p::before {
          background: $brand-secondary;
          border: 1px solid $border-color-dark;
          content: '';
          display: block;
          height: 13px;
          left: 0;
          position: absolute;
          top: 4px;
          width: 13px;
        }

        &:checked + p::after {
          color: $border-color-dark;
          content: '\2713';
          display: block;
          font-size: 10px;
          font-weight: 700;
          left: 2px;
          position: absolute;
          top: 3px;
        }
      }
    }

    input[type="checkbox"] {
      height: 0;
      opacity: 0;
      width: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.section--searchform {
  .form-element--pattern,
  .form-element--datepicker {
    input {
      border-right: none;

      &:focus {
        border-color: $brand-primary;

        +.input-group-addon {
          border: 1px solid $brand-primary;
          border-left: none;
        }
      }
    }
  }

  .form-submit {
    @include make-button--primary-1();
    @include size(100%, 38px);
  }

  @media screen and (max-width: $screen-md-max) {
    .form-element:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.datetimepicker {
  .fa-arrow-left, .fa-arrow-right {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../../images/svgicons/collapse-indicator.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10px;
  }

  .fa-arrow-left {
    transform: rotate(90deg);
  }

  .fa-arrow-right {
    transform: rotate(-90deg);
  }
}

.layout--press-review-index {
  font-family: Arial, sans-serif;

  .section--press_review-jumbotron {
    .section-block {
      p {
        font-size: $font-size-lg;
        line-height: 20px;
      }
    }

    .section-footer {
      display: none;
    }
  }

  .section--press-review-index {
    text-align: center;

    .section-header {
      display: none;
    }

    .section-block {
      .press_review-steps {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;

        @media (max-width: 1024px) {
          align-items: center;
          flex-direction: column;
        }

        @media (max-width: $screen-sm) {
          margin-bottom: 15px;
        }

        .step {
          background: #FAFAFA;
          font-size: $font-size-base;
          line-height: 16px;
          padding: 35px 15px;
          position: relative;
          text-align: left;
          width: calc(100%/3);

          @media (max-width: 1024px) {
            margin-bottom: 30px;
            width: 100%;
          }

          &+.step {
            margin-left: 15px;

            @media (max-width: 1024px) {
              margin-left: 0;
              padding-top: 50px;
            }
          }

          &:last-child {
            background: #A49A8E;
            color: #ffffff;
            font-size: $font-size-xlg;
            font-weight: $font-weight-bold;
            line-height: 23px;

            .step-desc {
              left: 50%;
              position: absolute;
              text-align: center;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 70%;
            }

            @media (max-width: 1024px) {
              padding-top: 70px;
            }
          }

          div:first-of-type {
            background-color: #000000;
            border-radius: 50%;
            height: 33px;
            left: 50%;
            position: absolute;
            top: -17px;
            transform: translateX(-50%);
            width: 33px;

            span {
              color: #ffffff;
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: $font-size-xlg;
              font-weight: $font-weight-bold;
            }
          }

          .step-name {
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            margin-bottom: 15px;
            text-align: center;
          }
        }
      }

      .section-btn {
        background: #A49A8E;
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        height: 50px;
        margin: auto;
        margin-bottom: 40px;
        padding-top: 15px;
        text-align: center;
        text-transform: none;

        span {
          color: #ffffff;
          display: block;
          font-family: $font-family-primary;
          font-size: $font-size-lg;
        }
      }

      .press_review-notice {
        text-align: center;

        p {
          font-size: $font-size-lg;
          line-height: 20px;
          margin: auto;
          margin-bottom: 55px;
          text-align: left;
          width: 80%;

          &:first-of-type {
            color: #F40202;
            font-size: $font-size-base;
          }
        }
      }
    }
  }
}

.layout--press-review-form {
  .section--press-review-form {
    .section-header {
      padding-bottom: 55px;
      text-align: center;

      .section-title {
        border-bottom: 3px solid #000000;
        display: inline-block;
        font-family: Arial, sans-serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 23px;
        padding-bottom: 15px;
        text-transform: uppercase;
      }
    }

    .section-block {
      background-color: #FAFAFA;
      padding-top: 55px;

      form {
        position: relative;

        .form-wrapper {
          > div:nth-of-type(2) {
            border-bottom: 2px solid #000000;
            margin-bottom: 0;
          }

          > div:nth-of-type(3) {
            padding-top: 30px;
            text-align: center;

            p {
              font-size: $font-size-lg;
              line-height: 20px;
              margin: auto;
              text-align: left;
              width: 50%;

              @media (max-width: 768px) {
                width: 80%;
              }
            }

            button {
              background: #A49A8E;
              border-radius: 5px;
              color: #ffffff;
              font-weight: $font-weight-bold;
              font-size: $font-size-lg;
              height: 50px;
              line-height: 22px;
              margin-top: 40px;
              text-transform: none;

              &.form-error {
                background: #D8D8D8;
              }

              @media (max-width: 768px) {
                width: 300px;
              }
            }
          }
        }

        .form-group {
          label,
          input,
          p,
          h2,
          h3 {
            font-family: Arial, sans-serif;
          }

          background-color: #ffffff;
          margin-bottom: 55px;

          &:nth-child(1) {
            background-color: #FAFAFA;
          }

          .form-subscriber-data,
          .form-invoicing-data {
            font-size: 15px;
            margin: auto;
            width: 75%;
          }

          .form-invoicing-data {
            padding: 55px 0;
          }

          .input-group,
          .form-data {
            display: inline-block;
            vertical-align: top;
            width: 49%;

            @media (max-width: 768px) {
              display: block;
              margin: auto;
              text-align: center;
              width: 85%;
            }
          }

          .form-data {
            margin-top: 60px;

            h3 {
              font-size: $font-size-xlg;
              font-weight: 900;
              line-height: 23px;
              margin-bottom: 15px;
            }

            p {
              font-size: 14px;
              font-weight: $font-weight-regular;
              line-height: 20px;
            }

            @media (max-width: 768px) {
              margin-top: 0;
            }
          }

          .input-group {
            label {
              font-size: $font-size-lg;
              font-weight: 900;
              margin-bottom: 8px;
              line-height: 18px;
              text-transform: uppercase;
            }

            input {
              border-radius: 0;
              border: 3px solid #000000;
              color: #000000;
              font-size: $font-size-lg;
              height: 55px;
              line-height: 20px;
              margin-bottom: 30px;

              &.form-error {
                border-color: #F40202;
                color: #F40202;
              }
            }

            .wiztopic-select-container,
            .wiztopic-select-header {
              padding: 0;
              position: relative;
            }

            .wiztopic-select-body {
              font-size: $font-size-lg;
              line-height: 20px;
            }

            .wiztopic-select-search {
              margin: 0;
            }

            .wiztopic-select-trigger {
              background-color: transparent;
              border: 0;

              &:before {
                border-width: 4px 0 0 4px;
                height: 12px;
                width: 12px;
              }
            }

            .radio-label {
              margin-bottom: 5px;
            }

            .entity.radio {
              > label {
                input,
                span {
                  display: inline-block;
                  height: unset;
                  vertical-align: middle;
                }

                input {
                  height: 14px;
                  width: 14px;
                }

                span {
                  font-size: 14px;
                  font-weight: $font-weight-regular;
                }
              }
            }

            .form-notice {
              margin-top: 25px;

              p {
                font-size: $font-size-base;
                line-height: 16px;
              }
            }

            @media (max-width: 768px) {
              margin-top: 40px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

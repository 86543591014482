.wizicon {
  @include size(15px, 15px);
  color: $brand-primary; // for fa-* icons.
  fill: $brand-primary;
  vertical-align: middle;
}

.alert .wizicon {
  fill: rgba(#fff, 0.8);

  &:focus,
  &:hover {
    fill: rgba(#fff, 1);
  }
}

.webidentity {
  @include webidentity-sizing($webidentity-width, 50%, 6px);
  cursor: pointer;
  display: block;

  .webidentity-icon {
    transition: background 0.3s ease-out;
  }
}

// Create all the variants. @see variables.
@each $webidentity-name, $webidentity-params in $webidentities {
  .webidentity-#{$webidentity-name} {
    @include webidentity-huer($webidentity-params...);
  }
}

.webidentity--highlight {
  @include webidentity-sizing(ceil($webidentity-width * 1.667), 50%, 10px);
}

.panel-title {
  .panel-collapse-icon {
    @include size(17px, 17px);
    fill: $brand-primary;
    float: right;
  }
}

.wizicon-card-cta-more {
  transform: rotate(-90deg);
}

.navbar-header {
  background-color: $brand-primary;
}

.navbar-knob {
  cursor: pointer;
  display: block;
  padding: 12.5px 15px;

  .navbar-knob-icon,
  .navbar-knob-text {
    display: inline-block;
    vertical-align: middle;
  }

  .navbar-knob-text {
    padding: 0 10px;
  }
}

.navbar-knob--menu {
  float: left;
}

.navbar-knob--alternate_link,
.navbar-knob--search {
  float: right;
}

.navbar-knob {
  background-color: $brand-primary;

  .navbar-knob-icon {
    .wizicon {
      fill: #fff;
      height: 20px;
      width: 20px;
    }
  }

  .navbar-knob-text {
    color: #fff;
    font-size: $font-size-md;
    font-weight: 700;
    letter-spacing: 0.035em;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.navbar--channel .nav-item--alternate_link {
  .nav-link-icon + .nav-link-text {
    margin-left: 5px;
  }
}

@media screen and (max-width: $grid-float-breakpoint-max) {
  .navbar--channel .nav-item--alternate_link {
    display: none;
  }

  .offie-dropdown--1 {
    transition: unset;

    &.offie--active {
      position: static;
      transition: unset;
    }
  }
}

.layout-hook--header {
  .section--header {
    padding: 0;

    .section-container {
      padding: 0;
      width: 100%;

      .header-corporate {
        background-color: $brand-grey;

        > ul {
          display: flex;
          justify-content: flex-end;
          list-style: none;
          margin: 0;
          padding: 0;

          li.nav-item {
            display: flex;
            justify-content: center;
            line-height: initial;
            max-width: 220px;
            padding: 12px 0;
            width: 100%;

            &:last-child {
              background: $brand-secondary;

              .nav-link {
                .nav-link-text {
                  color: $brand-primary;
                }
              }
            }

            .nav-link {
              .nav-link-text {
                color: $brand-dark;
                font-family: $font-family-primary;
                font-size: $font-size-md;
                line-height: 16px;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .menu-mobile {
        display: none;

        .block-menu-top {
          align-items: center;
          display: flex;
          padding: 15px 30px;

          .header-buger {
            align-items: center;
            background-color: $brand-primary;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            flex-basis: 50px;
            flex-direction: column;
            height: 50px;
            justify-content: center;
            margin-right: 20px;
            width: 50px;

            .icon-burger {
              background-color: $brand-secondary;
              border-radius: 2px;
              height: 3px;
              margin: 3.5px 0;
              padding: 0 15px;
            }
          }

          .section-img {
            flex: 1;
            margin: 0;
            max-width: 300px;

            img {
              margin: 0;
              min-width: 185px;
            }
          }
        }

        .block-menu-detail {
          -webkit-transition: all ease .5s;
          background-color: $brand-mobile-menu;
          height: 100vh;
          left: -100%;
          overflow-y: auto;
          padding: 30px 16px 30px;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 9;

          &.active {
            left: 0;
          }

          .header-beamer-wrapper {
            border-bottom: 1px solid $brand-secondary;
            padding-bottom: 20px;
          }

          .header-corporate {
            background-color: transparent;

            > ul {
              flex-direction: column;

              li.nav-item {
                background-color: transparent;
                display: block;
                padding: 0;
                width: 100%;

                .nav-link {
                  display: block;
                  padding: 12px 14px 12px 0;
                  width: 100%;

                  .nav-link-text {
                    color: $brand-secondary;
                  }
                }

                &:last-child {
                  background-color: transparent;

                  .nav-link .nav-link-text {
                    color: $brand-secondary;
                  }
                }
              }
            }
          }

          .navbar--channel {
            > .container {
              flex-direction: column;
              padding: 0;

              &::before {
                content: none;
              }

              .navbar-element--main {
                width: 100%;

                .navbar-nav {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                  width: 100%;

                  .nav-item {
                    .offie-dropdown {
                      &.offie--active {
                        position: static;
                      }
                    }

                    .nav-link {
                      &.offie--active {
                        .nav-link-icon {
                          -webkit-transform: rotate(180deg);
                        }
                      }

                      .nav-link-icon {
                        -webkit-transition: all ease .3s;
                      }

                      .nav-link-text {
                        color: $brand-secondary;
                      }
                    }
                  }
                }
              }

              .nav-item--search {
                flex-basis: unset;
                padding-top: 20px;
                width: 100%;

                .form--clientsearch {
                  .input-group {
                    .input-group-btn {
                      .btn-default .wizicon {
                        g > g {
                          color: $brand-secondary;
                          fill: $brand-secondary;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .close-menu-btn {
            background-color: $brand-primary;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 50px;
            padding: 6px;
            position: absolute;
            right: 24px;
            top: 30px;
            width: 50px;

            .icon-burger-close {
              background: $brand-secondary;
              height: 3px;
              width: 100%;

              &:first-child {
                -webkit-transform: translate(0px, 17px) rotate(45deg);
              }

              &:last-child {
                -webkit-transform: translate(0px, 14px) rotate(-45deg);
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      .section-container {
        .menu-mobile {
          .block-menu-top {
            .section-img {
              max-width: unset;

              svg {
                min-width: unset;
                height: 30px;
                width: 210px;
              }
            }
          }
        }
      }
    }
  }

  .section--jumbotron-channel {
    .navbar--channel {
      & ~ .container {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .section--header {
      .menu-desktop {
        display: none;
      }

      .section-container {
        .section-wrapper {
          .menu-mobile {
            display: block;
          }
        }
      }
    }

    .section--jumbotron-channel {
      display: none;
    }
  }
}

@media screen and (min-width: $grid-float-breakpoint) {
  // Always show the level0 on tablet and wider devices.
  .offie-collapse--0 {
    position: static;
    opacity: 1;
    overflow: visible;
    visibility: visible;
  }

  // Always hide the knob (toggle).
  .navbar-knob {
    display: none;
  }
}

// Card / *
.card-block {
  padding: 0;
}

.card-title {
  @include make-font-2();
  padding-bottom: 25px;
}

.card-subtitle {
  @include make-font-8();
  margin-bottom: 18px;
}

.card-text {
  @include make-font-1();
  display: none;
  font-family: $font-family-primary;
  font-size: $font-size-base;
}

// Card / Spokesperson
.card--spokesperson {
  align-items: stretch;
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;

  .card-img {
    @include image-view(4, 3);
  }

  div.js-buckle-container {
    flex: 1;
  }

  .card-block {
    background-color: $brand-secondary;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 9px 39px 7px;

    .card-title {
      padding-bottom: 13px;
    }

    .card-subtitle {
      padding-bottom: 22px;
    }
  }

  .card-text {
    display: block;
    line-height: 16px;
    margin-top: auto;
    overflow-wrap: break-word;
    hyphens: auto;
    word-wrap: break-word;

    [class*=card-text--] {
      margin-bottom: 9px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .wizicon {
      display: none;
    }
  }

  .card-text--email {
    display: flex;
    flex-wrap: wrap;

    span:nth-of-type(1) {
      padding-right: 4px;
    }

    .address-email {
      word-break: break-all;
    }
  }
}

// Card / Performer
.card--performer {
  .card-title {
    font-family: $font-family-secondary;
    font-size: $font-size-xl;
  }

  .card-subtitle {
    color: $text-muted;
  }

  .card-img {
    margin-bottom: $card-spacer-y;

    > img {
      border: 1px solid $brand-primary;
      border-radius: 50%;
      margin: auto;
    }
  }

  .card-subtitle {
    min-height: $line-height-computed * 2;
    padding-bottom: 20px;
    position: relative;

    &:after {
      border-bottom: 2px solid $brand-primary;
      bottom: 0;
      content: '';
      display: block;
      left: 40%;
      position: absolute;
      width: 20%;
    }
  }

  &.card--grid {
    .card-img {
      width: 140px;
    }

    .card-title,
    .card-subtitle {
      text-align: center;
    }
  }

  &.card--list {
    .card-block {
      padding-left: 0;
      padding-right: 0;

      @media screen and (min-width: $screen-sm-min) {
        padding: 0;
      }
    }
  }
}

.card-element--tags.alway-first-tag {
  background-color: $brand-red-dark;
  border-radius: 5px;
  color: $brand-secondary;
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 1;

  .list-item {
    margin-bottom: 0;

    .label--tags {
      border: none;
      color: $brand-secondary;

      &[href]:active,
      &[href]:focus,
      &[href]:hover {
        border: none;
        color: $brand-secondary;
      }
    }
  }
}

.card-block {
  .card-btn {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    .card-btn-text {
      color: $brand-primary;
      font-family: $font-family-bold;
      font-size: $font-size-base;
      line-height: 16px;
      padding-right: 22px;
    }

    .card-btn-icon {
      .wizicon {
        height: 16px;
        width: 20px;

        > g > g {
          color: $brand-primary;
          fill: $brand-primary;
        }
      }
    }
  }
}

.card--default {
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;

  .card-img {
    @include image-view(16, 9);
  }

  .card-block {
    background: $brand-secondary;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 18px 23px 34px 11px;

    .card-title {
      a {
        @include text-line-clamp(4);
      }
    }

    .card-element--tags {
      margin-bottom: 45px;
      min-height: 24px;
    }

    .card-sub-block {
      margin-top: auto;
    }
  }
}

.card--topic {
  &.card--highlight {
    align-items: stretch;
    display: flex;

    .left-img-highlight {
      display: inline-block;
      max-width: 732px;
      width: 100%;

      .card-img {
        @include image-view(16, 9);
      }
    }

    .card-block {
      background-color: $brand-primary;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 17px 51px 41px;

      .card-element--tags.alway-first-tag {
        left: 0;
        margin-bottom: 14px;
        position: unset;
        top: 0;
        width: fit-content;

        .list--tags {
          max-height: unset;
          overflow: unset;

          .list-item {
            .label--tags {
              background: $brand-red-dark;
              border-color: $brand-red-dark;
            }
          }
        }
      }

      .card-element--tags:not(.alway-first-tag) {
        .list-item {
          margin-bottom: 10px;
        }
      }

      .list--tags {
        margin: 0 -4.5px;
        max-height: 24px;
        overflow: hidden;

        .list-item {
          display: inline-flex;
          margin: 0 4.5px;

          .label--tags {
            background: $brand-secondary;
            border: 0;

            &:hover {
              border: 0;
            }
          }
        }
      }

      .card-subtitle {
        color: $brand-secondary;
        margin-bottom: 14px;
      }

      .card-title {
        font-family: $font-family-bold;
        font-size: $font-size-xxxl;
        line-height: 32px;
        padding-bottom: 34px;

        a {
          @include text-line-clamp(5);
          color: $brand-secondary;
        }
      }

      .card-btn {
        margin-top: auto;

        .card-btn-text {
          color: $brand-secondary;
        }

        .card-btn-icon {
          .wizicon {
            height: 16px;
            width: 20px;

            path {
              fill: $brand-secondary;
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-lg) {
      .card-block {
        .card-title {
          a {
            @include text-line-clamp(2);
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      flex-direction: column;

      .left-img-highlight {
        max-width: unset;
      }

      .card-block {
        min-height: 300px;
        padding: 16px;
      }
    }
  }
}

// Card Expert
.card--expert {
  .card-img {
    padding-top: calc((100% / 4) * 3);
  }

  .card-block {
    padding: 25px 15px 31px;

    .card-title {
      padding-bottom: 20px;

      a {
        -webkit-line-clamp: 1;
        min-height: unset;
      }
    }

    .card-subtitle {
      @include text-line-clamp(4);
    }
  }

  &.card--alt {
    @include make-card-layout--list(150px);

    .card-title {
      font-size: $font-size-xl;
    }
  }
}

.card--asset {
  &:not(.card--alt) {
    background-color: $brand-grey;
    border: 0;

    .card-img {
      padding-top: calc((100% / 4) * 3);
    }

    .card-block {
      background-color: $brand-grey;
      padding: 17px 20px;

      .card-title {
        @include text-line-clamp(4);

        font-family: $font-family-bold;
        font-size: $font-size-md;
        min-height: 64px;
        padding-bottom: 0;
      }
    }
  }

  .card-img {
    .card-img-icon {
      background-color: $brand-primary;
      border-radius: 50%;
      display: inline-block;
      left: 8px;
      position: absolute;
      right: unset;
      top: 8px;
      width: auto;
      z-index: 3;

      img,
      .wizicon {
        @include size(48px, 48px);
      }
    }
  }
}

.card--clipping {
  .card-block {
    background-color: $brand-secondary;
    display: flex;
    flex-direction: row;

    .card-btn {
      justify-content: flex-start;

      &.card-btn-download {
        color: $brand-primary;
        font-family: $font-family-bold;
        font-size: $font-size-base;
        line-height: 16px;
        padding-top: 10px;
      }
    }
  }

  .card-img {
    align-items: center;
    display: inline-flex;
    flex-basis: 289px;
    margin-bottom: 0;

    > a {
      @include image-view(16, 9);

      display: inline-flex;
    }
  }

  .right-detail {
    padding: 0 25px;
    flex: 1;
  }

  .card-subtitle {
    color: $brand-gray;
    font-family: $font-family-bold;
    margin-bottom: 0;
    padding-bottom: 13px;

    &.main-organisation-name {
      color: $brand-primary;
      font-family: $font-family-bold;
      font-size: $font-size-md;
      line-height: 16px;
      padding-bottom: 19px;
    }
  }

  .card-title {
    @include text-line-clamp(2);
    font-family: $font-family-bold;
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  @media screen and (max-width: $screen-md) {
    .card-block {
      flex-direction: column;

      .card-btn {
        justify-content: flex-end;
      }
    }

    .card-img {
      flex: 1;
      margin-bottom: 0;
    }

    .right-detail {
      padding: 16px;
    }
  }
}

.card--feature {
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;

  .card-img {
    @include image-view(4, 3);

    .wizicon {
      display: none;
    }
  }
}

// Cards
.card--feed-facebook {
  @include card-sizing(10px, 10px);
  @include make-card-layout--list(40px);

  background-color: $brand-light-gray;
  padding: 15px;

  .card-img {
    margin-left: 0;
  }

  .card-title {
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: 700;
  }

  .card-title-aside {
    color: $text-muted;
    font-size: $font-size-sm;
    font-weight: 400;
  }

  .card-subtitle {
    text-transform: inherit;
  }

  .card-text {
    color: $text-color;
    font-family: $font-family-primary;
  }

  @media screen and (max-width: $screen-sm) {
    .card-img {
      float: left;
      margin-right: 15px;
      width: 40px;

      > img {
        width: 40px;
      }
    }

    .card-block {
      overflow: hidden;
    }
  }
}

.card--contactform {
  label {
    color: $brand-primary;
    font-weight: $font-weight-regular;
    margin-bottom: 5px;
  }

  .checkbox input + label {
    padding: 0;
    margin-bottom: 0;
  }

  .checkbox-input {
    margin-bottom: 14px;
  }

  .row {
    margin-left: -40px;
    margin-right: -40px;

    div[class*="col-"] {
      padding: 0 40px;
    }
  }

  input.form-control {
    height: 48px;
  }

  textarea.form-control {
    min-height: 92px;
  }

  .form-group--captcha {
    margin-bottom: 5px;
  }

  @media screen and (max-width: $screen-md) {
    .row {
      margin:0 -16px;

      div[class*="col-"] {
        padding: 0 16px;
      }
    }
  }
}

// Card / Event
.card--event {
  .card-block {
    background: $brand-secondary;
    padding: 16px 12px;

    .card-subtitle {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;

      .wizicon {
        @include size(16px, 16px);

        flex-shrink: 0;
        margin-right: 12px;
      }

      &.card-subtitle-location {
        .wizicon {
          margin-right: 16px;
        }
      }
    }

    .card-title {
      @include text-line-clamp(4);

      a {
        @include text-line-clamp(4);
      }

      margin-bottom: 16px;
      padding-bottom: 0;
    }

    .card-element--tags {
      margin-bottom: 22px;
    }

    .card-subtitle-location {
      margin-bottom: 32px;
    }

    &:has(.card-subtitle-location) {
      .card-element--tags {
        margin-bottom: 6px;
      }
    }

    .card-btn {
      padding-bottom: 16px;
      margin-right: 14px;

      .card-btn-text {
        padding-right: 8px;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .card-subtitle-date {
      br {
        display: none;
      }
    }
  }
}
